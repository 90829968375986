import axios from "axios";

import { constants, urls } from "../../data";
import storage from "../storage";

import { API_BASE_URL, FRONT_BASE_URL } from "../../data/params";

const auth = {
  token: function() {
    return storage.get(constants.TOKEN);
  },
  sigUp: function({ email, password, done }) {
    axios.defaults.headers.common["auth"] = storage.get(constants.TOKEN); // for all requests

    return axios.post(API_BASE_URL + "/users", { email, password }).then(({ data }) => {
      // window.location.href = FRONT_BASE_URL + urls.app.company.base;
      done && done();
    });
  },
  login: function({ email, password, done }) {
    return axios.post(API_BASE_URL + "/auth/login", { email, password }).then(({ data }) => {
      storage.clear();
      storage.set(constants.TOKEN, data.token);
      storage.set(constants.USER, data.user);
      // window.location.href = FRONT_BASE_URL + urls.app.company.base;
      done && done();
    });
  },
  logout: function() {
    storage.clear();
    window.location.href = FRONT_BASE_URL + urls.front.base;
  }
};

export default auth;
