import urls from "../urls";
import strings from "../strings";
import api from "../../helpers/api";

const schemas = {
  visitor: {
    right: [
      {
        title: strings.logIn,
        path: urls.front.login,
        icon: "login"
      }
    ]
  },

  user: {
    left: [
      {
        title: strings.home,
        path: urls.app.base,
        icon: "home"
      }
    ],
    right: [
      {
        title: strings.logOut,
        path: urls.front.logout,
        icon: "logout"
      }
    ]
  },

  admin: {
    right: [],
    left: []
  }
};

const navigation = {
  left: [],
  right: []
};

if (!api.user.current()) {
  navigation.right = [...navigation.right, ...schemas.visitor.right];
} else {
  navigation.left = [...navigation.left, ...schemas.user.left];

  navigation.right = [...navigation.right, ...schemas.user.right];

  if (api.user.current().role === "ADMIN") {
    navigation.left = [...navigation.left, ...schemas.admin.left];
    navigation.right = [...navigation.right, ...schemas.admin.right];
  }
}

export default navigation;
