import { useState, useEffect, useRef } from "react";
import storage from "./storage";

export function useApi(controller, storageKey = controller.name) {
  const [data, setData] = useState(storageKey && storage.get(storageKey));
  const [loading, setLoading] = useState(!storage.get(storageKey));
  const [error, setError] = useState(null);

  const start = function(payload) {
    setLoading(true);
    controller(payload)
      .then(res => {
        if (res.data) {
          setData(res.data);
          if (storageKey) storage.set(storageKey, res.data);
        } else {
          setData(res);
          if (storageKey) storage.set(storageKey, res);
        }
      })
      .catch(err => {
        setError(err.response);
      })
      .then(() => {
        setLoading(false);
      });
  };
  return { data, loading, error, fetch: start };
}

export function useFetch(run, option) {
  option = { storageKey: run.name, auto: false, ...option };
  const [data, setData] = useState(option.storageKey && storage.get(option.storageKey));
  const [loading, setLoading] = useState(!storage.get(option.storageKey));
  const [error, setError] = useState(null);

  const start = function(payload) {
    run(payload)
      .then(res => {
        if (res.data) {
          setData(res.data);
          if (option.storageKey) storage.set(option.storageKey, res.data);
        } else {
          setData(res);
          if (option.storageKey) storage.set(option.storageKey, res);
        }
      })
      .catch(err => {
        setError(err.response);
      })
      .then(() => {
        setLoading(false);
      });
  };
  // useEffect(() => {
  //   if (option.auto) {
  //     start();
  //   }
  // }, [option.storageKey]);
  return { data, loading, error, fetch: start };
}

export function usePagination(limit, fetchFn) {
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const fetch = useApi(() => fetchFn(limit, offset));

  // React.useEffect(() => {
  //   fetch.fetch();
  // }, [offset]);

  const onPageChange = page => {
    setPage(page);
    setOffset(limit * (page - 1));
  };

  const total = fetch.data && fetch.data.count ? Math.ceil(fetch.data.count / limit) : 0;

  return { page, total, onPageChange, ...fetch };
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
