import * as Sentry from "@sentry/browser";
import { params } from "../../data";

function sentry(user = null) {
  if (!params.SENTRY_DSN) return;

  Sentry.init({
    dsn: params.SENTRY_DSN,
    release: `${params.APP_ID}@${params.VERSION}`,
    environment: params.APP_ENV
  });

  user = user || {};
  Sentry.configureScope(scope => {
    scope.setExtra("app", params.APP_ID);
    scope.setExtra("release", params.VERSION);

    scope.setTag("user_mode", user.role);
    scope.setTag("url", params.FRONT_BASE_URL);

    scope.setUser({
      email: user.email,
      name: user.first_name + " " + user.last_name,
      id: user.id
    });
    // scope.clear();
  });
}

export default sentry;
