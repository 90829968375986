import React, { Component } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import { constants, params, urls } from "./data";
import storage from "./helpers/storage";

import "./assets/styles/app.css";
import "./assets/styles/src/canvas-palette.css";
import "./assets/styles/src/canvas-utils.css";

import api from "./helpers/api";

import Landing from "./pages/Landing";
import SignUp from "./pages/Auth/SignUp";
import Login from "./pages/Auth/Login";
import Logout from "./pages/Auth/Logout";
import Home from "./pages/Home";
import vendors from "./helpers/vendors";
import Sample from "./pages/Utils/Sample";
import Book from "./pages/Store/Book";
import Reader from "./pages/Reader";

vendors.init();

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !api.user.logged() ? (
        <Redirect
          to={{
            pathname: urls.front.base,
            state: { from: props.location }
          }}
        />
      ) : (
        <Component {...props} {...rest} />
      )
    }
  />
);

const BaseRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.publicOnly && api.user.logged() ? (
        <Redirect
          to={{
            pathname: urls.app.base,
            state: { from: props.location }
          }}
        />
      ) : (
        <Component {...props} {...rest} />
      )
    }
  />
);

class AppRouter extends Component {
  componentDidMount() {
    storage.set(constants.VERSION, params.VERSION);
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <PrivateRoute exact path={urls.app.base} component={Home} {...this.state} />

          <BaseRoute exact path={urls.store.recueilohada} component={Book} {...this.state} />
          <BaseRoute exact path={urls.store.treaties} component={Reader} {...this.state} />

          <BaseRoute exact path={urls.front.login} component={Login} {...this.state} />
          <BaseRoute exact path={urls.front.signup} component={SignUp} {...this.state} />
          <PrivateRoute exact path={urls.front.logout} component={Logout} {...this.state} />

          <PrivateRoute exact path={urls.utils.sample} component={Sample} {...this.state} />

          <BaseRoute exact publicOnly path={urls.front.base} component={Landing} {...this.state} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default AppRouter;
