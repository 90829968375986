const toast = require("react-toastify").toast;

export function successToast(message) {
  if (typeof message === "string")
    toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
}

export function warningToast(message) {
  if (typeof message === "string")
    toast.warning(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
}

export function errorToast(message) {
  if (typeof message === "string")
    toast.error(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
}

export function toastErrors(error) {
  try {
    if (error && error.message) {
      if (typeof error.message === "string") toast.error(error.message);
      else
        Object.keys(error.message).forEach(key => {
          toast.error(key + ": " + error.message[key].join());
        });
    }
  } catch (e) {
    toast.error("Oops something unexpected happened. Please contact the Beans Team.");
    return e;
  }
}

export default { success: successToast, warning: warningToast, error: errorToast, errors: toastErrors };
