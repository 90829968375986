const ohada = {
  revision: 9,
  createdAt: "11/13/2019",
  updatedAt: "11/14/2019",
  title: "OHADA, Les Traités",
  path: "/recueilohada",
  color: "brush",
  value: {
    headerOneList: [
      {
        name: "TRAITÉ RELATIF À L’HARMONISATION DU DROIT DES AFFAIRES EN AFRIQUE SIGNÉ À PORT-LOUIS LE 17 OCTOBRE 1993\u200c\u200c",
        info: "(Journal Officiel N°4 du 1er novembre 1997)",
        headerTwoList: [
          {
            name: "PRÉAMBULE",
            headerThreeList: [
              {
                name: "",
                paragraphs: [
                  {
                    value: "Le Président de la République du BÉNIN,",
                    style: false
                  },
                  {
                    value: "Le Président du BURKINA FASO,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du CAMEROUN,",
                    style: false
                  },
                  {
                    value: "Le Président de la République CENTRAFRICAINE,",
                    style: false
                  },
                  {
                    value: "Le Président de la République Fédérale Islamique des COMORES,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du CONGO,",
                    style: false
                  },
                  {
                    value: "Le Président de la République de CÔTE D’IVOIRE,",
                    style: false
                  },
                  {
                    value: "Le Président de la République GABONAISE,",
                    style: false
                  },
                  {
                    value: "Le Président de la République de GUINÉE ÉQUATORIALE,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du MALI,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du NIGER,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du SÉNÉGAL,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du TCHAD,",
                    style: false
                  },
                  {
                    value: "Le Président de la République TOGOLAISE,",
                    style: false
                  },
                  {
                    value: "Hautes parties contractantes au Traité relatif à l’harmonisation du droit des affaires en Afrique,",
                    style: false
                  },
                  {
                    value:
                      "Déterminés à accomplir de nouveaux progrès sur la voie de l’unité africaine et à établir un courant de confiance en faveur des économies de leurs pays en vue de créer un nouveau pôle de développement en Afrique ;",
                    style: false
                  },
                  {
                    value: "Réaffirmant leur engagement en faveur de l’institution d’une communauté économique africaine ;",
                    style: false
                  },
                  {
                    value:
                      "Convaincus que l’appartenance à la zone franc, facteur de stabilité économique et monétaire, constitue un atout majeur pour la réalisation progressive de leur intégration économique et que cette intégration doit également être poursuivie dans un cadre africain plus large ;",
                    style: false
                  },
                  {
                    value:
                      "Persuadés que la réalisation de ces objectifs suppose la mise en place dans leurs États d’un Droit des Affaires harmonisé, simple, moderne et adapté afin, de faciliter l’activité des entreprises ;",
                    style: false
                  },
                  {
                    value:
                      "Conscients qu’il est essentiel que ce droit soit appliqué avec diligence, dans les conditions propres à garantir la sécurité juridique des activités économiques afin, de favoriser l’essor de celles-ci et d’encourager l’investissement ;",
                    style: false
                  },
                  {
                    value: "Désireux de promouvoir l’arbitrage comme instrument de règlement des différends contractuels ;",
                    style: false
                  },
                  {
                    value: "Décidés à accomplir en commun de nouveaux efforts en vue d’améliorer la formation des magistrats et des auxiliaires de justice.",
                    style: false
                  },
                  {
                    value: "Conviennent de ce qui suit :",
                    style: "font-bold"
                  }
                ]
              }
            ]
          },
          {
            name: "Titre I : Dispositions générales",
            headerThreeList: [
              {
                name: "Article premier",
                paragraphs: [
                  {
                    value:
                      "Le présent traité a pour objet l’harmonisation du droit des affaires dans les États-Parties par l’élaboration et l’adoption de règles communes simples, modernes et adaptées à la situation de leurs économies, par la mise en œuvre de procédures judiciaires appropriées et, par l’encouragement au recours à l’arbitrage pour le règlement des différends contractuels.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 2",
                paragraphs: [
                  {
                    value:
                      "Pour l’application du présent traité, entre dans le domaine du droit des affaires l’ensemble des règles relatives au droit des sociétés et au statut juridique des commerçants, au recouvrement des créances, aux sûretés et aux voies d’exécution, au régime du redressement des entreprises et de la liquidation judiciaire, au droit de l’arbitrage, au droit du travail, au droit comptable, au droit de la vente et des transports, et toute autre matière que le Conseil des Ministres déciderait, à l’unanimité, d’y inclure, conformément à l’objet du présent traité et aux dispositions de l’article 8 ci-après.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 3",
                paragraphs: [
                  {
                    value:
                      "La réalisation des tâches prévues au présent Traité est assurée par une organisation dénommée Organisation pour l’Harmonisation en Afrique du Droit des Affaires (OHADA) comprenant un Conseil des Ministres et une Cour Commune de Justice et d’Arbitrage.",
                    style: false
                  },
                  {
                    value: "Le Conseil des Ministres est assisté d’un Secrétariat Permanent auquel est rattachée une École Régionale Supérieure de la Magistrature.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 4",
                paragraphs: [
                  {
                    value: "Des règlements pour l’application du présent traité seront pris chaque fois que de besoin, par le Conseil des Ministres, à la majorité absolue.",
                    style: false
                  }
                ]
              }
            ]
          },
          {
            name: "Titre II : Les Actes Uniformes",
            headerThreeList: [
              {
                name: "Article 5",
                paragraphs: [
                  {
                    value: "Les actes pris pour l’adoption des règles communes prévues à l’article premier du présent traité sont qualifiés « Actes Uniformes ».",
                    style: false
                  },
                  {
                    value:
                      "Les Actes Uniformes peuvent inclure des dispositions d’incrimination pénale. Les États- Parties s’engagent à déterminer les sanctions pénales encourues.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 6",
                paragraphs: [
                  {
                    value:
                      "Les Actes Uniformes sont préparés par le Secrétariat Permanent en concertation avec les gouvernements des États-Parties. Ils sont délibérés et adoptés par le Conseil des Ministres après avis de la Cour Commune de Justice et d’Arbitrage.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 7",
                paragraphs: [
                  {
                    value:
                      "Les projets d’Actes Uniformes sont communiqués par le Secrétariat Permanent aux gouvernements des États-Parties qui disposent d’un délai de quatre-vingt-dix jours à compter de la date de la réception de cette communication pour faire parvenir au Secrétariat Permanent leurs observations écrites.",
                    style: false
                  },
                  {
                    value:
                      "À l’expiration de ce délai, le projet d’Acte Uniforme, accompagné des observations des États- Parties et d’un rapport du Secrétariat Permanent, est immédiatement transmis pour avis par ce dernier à la Cour Commune de Justice et d’Arbitrage. La Cour donne son avis dans un délai de trente jours à compter de la date de la réception de la demande de consultation.",
                    style: false
                  },
                  {
                    value:
                      "A l’expiration de ce nouveau délai, le Secrétariat Permanent met au point le texte définitif du projet d’Acte Uniforme, dont il propose l’inscription à l’ordre du jour du plus prochain Conseil des Ministres.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 8",
                paragraphs: [
                  {
                    value: "L’adoption des Actes Uniformes par le Conseil des Ministres requiert l’unanimité des représentants des États-Parties présents et votants.",
                    style: false
                  },
                  {
                    value: "L’adoption des Actes Uniformes n’est valable que si les deux tiers au moins des États-Parties sont représentés.",
                    style: false
                  },
                  {
                    value: "L’abstention ne fait pas obstacle à l’adoption des Actes Uniformes.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 9",
                paragraphs: [
                  {
                    value:
                      "Les Actes Uniformes entrent en vigueur quatre-vingt-dix jours après leur adoption sauf modalités particulières d’entrée en vigueur prévues par l’Acte Uniforme lui-même. Ils sont opposables trente jours francs après leur publication au Journal Officiel de l’OHADA. Ils sont également publiés au Journal Officiel des États-Parties ou par tout autre moyen approprié.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 10",
                paragraphs: [
                  {
                    value:
                      "Les Actes Uniformes sont directement applicables et obligatoires dans les États-Parties, nonobstant toute disposition contraire de droit interne, antérieure ou postérieure.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 11",
                paragraphs: [
                  {
                    value: "Le Conseil des Ministres approuve sur proposition du Secrétaire Permanent le programme annuel d’harmonisation du droit des affaires.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 12",
                paragraphs: [
                  {
                    value: "Les Actes Uniformes ne peuvent être modifiés que dans les conditions prévues par les articles 7 à 9 ci-dessus, à la demande de tout États-Partie.",
                    style: false
                  }
                ]
              }
            ]
          },
          {
            name: "Titre III : Le contentieux relatif à l’interprétation et à l’application des Actes Uniformes",
            headerThreeList: [
              {
                name: "Article 13",
                paragraphs: [
                  {
                    value: "Le contentieux relatif à l’application des Actes Uniformes est réglé en première instance et en appel par les juridictions des États-Parties.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 14",
                paragraphs: [
                  {
                    value:
                      "La Cour Commune de Justice et d’Arbitrage assure dans les États-Parties l’interprétation et l’application communes du présent traité, des règlements pris pour son application et des Actes Uniformes.",
                    style: false
                  },
                  {
                    value:
                      "La Cour peut être consultée par tout État-Partie ou par le Conseil des Ministres sur toute question entrant dans le champ de l’alinéa précédent. La même faculté de solliciter l’avis consultatif de la Cour est reconnue aux juridictions nationales saisies en application de l’article 13 ci-dessus.",
                    style: false
                  },
                  {
                    value:
                      "Saisie par la voie du recours en cassation, la Cour se prononce sur les décisions rendues par les juridictions d’Appel des États-Parties dans toutes les affaires soulevant des questions relatives à l’application des Actes Uniformes et des règlements prévus au présent traité à I ‘exception des décisions appliquant des sanctions pénales.",
                    style: false
                  },
                  {
                    value:
                      "Elle se prononce dans les mêmes conditions sur les décisions non susceptibles d’appel rendues par toute juridiction des États-Parties dans les mêmes contentieux.",
                    style: false
                  },
                  {
                    value: "En cas de cassation, elle évoque et statue sur le fond.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 15",
                paragraphs: [
                  {
                    value:
                      "Les pourvois en cassation prévus à l’article 14 ci-dessus sont portés devant la Cour Commune de Justice et d’Arbitrage, soit directement par l’une des parties à l’instance, soit sur renvoi d’une juridiction nationale statuant en cassation saisi d’une affaire soulevant des questions relatives à l’application des Actes Uniformes.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 16",
                paragraphs: [
                  {
                    value:
                      "La saisine de la Cour Commune de Justice et d’Arbitrage suspend toute procédure de cassation engagée devant une juridiction nationale contre la décision attaquée. Toutefois cette règle n’affecte pas les procédures d’exécution.",
                    style: false
                  },
                  {
                    value:
                      "Une telle procédure ne peut reprendre qu’après arrêt de la Cour Commune de Justice et d’Arbitrage se déclarant incompétente pour connaître de l’affaire.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 17",
                paragraphs: [
                  {
                    value:
                      "L’incompétence manifeste de la Cour Commune de Justice et d’Arbitrage peut être soulevée d’office ou par toute partie au litige in limine litis. La Cour se prononce dans les trente jours.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 18",
                paragraphs: [
                  {
                    value:
                      "Toute partie qui, après avoir soulevé l’incompétence d’une juridiction nationale statuant en cassation estime que cette juridiction a, dans un litige la concernant, méconnu la compétence de la Cour Commune de Justice et d’Arbitrage peut saisir cette dernière dans un délai de deux mois à compter de la notification de la décision contestée.",
                    style: false
                  },
                  {
                    value: "La Cour se prononce sur sa compétence par un arrêt qu’elle notifie tant aux parties qu’à la juridiction en cause.",
                    style: false
                  },
                  {
                    value: "Si la Cour décide que cette juridiction s’est déclarée compétente à tort, la décision rendue par cette juridiction est réputée nulle et non avenue.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 19",
                paragraphs: [
                  {
                    value:
                      "La procédure devant la Cour Commune de Justice et d’Arbitrage est fixée par un Règlement adopté par le Conseil des Ministres dans les conditions prévues à l’article 8 ci-dessus publié au Journal Officiel de l’OHADA. Il est également publié au Journal Officiel des États-Parties ou par tout autre moyen approprié. Cette procédure est contradictoire. Le Ministère d’un avocat est obligatoire. L’audience est publique.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 20",
                paragraphs: [
                  {
                    value:
                      "Les arrêts de la Cour Commune de Justice et d’Arbitrage ont l’autorité de la chose jugée et la force exécutoire. Ils reçoivent sur le territoire de chacun des États-Parties une exécution forcée dans les mêmes conditions que les décisions des juridictions nationales. Dans une même affaire, aucune décision contraire à un arrêt de la Cour Commune de Justice et d’Arbitrage ne peut faire l’objet d’une exécution forcée sur le territoire d’un État-Partie.",
                    style: false
                  }
                ]
              }
            ]
          },
          {
            name: "Titre IV : L’arbitrage",
            headerThreeList: [
              {
                name: "Article 21",
                paragraphs: [
                  {
                    value:
                      "En application d’une clause compromissoire ou d’un compromis d’arbitrage, toute partie à un contrat, soit que l’une des parties ait son domicile ou sa résidence habituelle dans un des États-Parties, soit que le contrat soit exécuté ou à exécuter en tout ou partie sur le territoire d’un ou plusieurs États-Parties, peut soumettre un différend d’ordre contractuel à la procédure d’arbitrage prévue par le présent titre.",
                    style: false
                  },
                  {
                    value:
                      "La Cour Commune de Justice et d’Arbitrage ne tranche pas elle-même les différends. Elle nomme ou confirme les arbitres, est informée du déroulement de l’instance, et examine les projets de sentences, conformément à l’article 24 ci-après.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 22",
                paragraphs: [
                  {
                    value:
                      "Le différend peut être tranché par un arbitre unique ou par trois arbitres. Dans les articles suivants, l’expression «l’arbitre» vise indifféremment le ou les arbitres.",
                    style: false
                  },
                  {
                    value:
                      "Lorsque les parties sont convenues que le différend sera tranché par un arbitre unique, elles peuvent le désigner d’un commun accord pour confirmation par la Cour. Faute d’entente entre les parties dans un délai de trente jours à partir de la notification de la demande d’arbitrage à l’autre partie, l’arbitre sera nommé par la Cour.",
                    style: false
                  },
                  {
                    value:
                      "Lorsque trois arbitres ont été prévus, chacune des parties - dans la demande d’arbitrage ou dans la réponse à celle-ci - désigne un arbitre indépendant pour confirmation par la Cour. Si l’une des parties s’abstient, la nomination est faite par la Cour. Le troisième arbitre qui assume la présidence du tribunal arbitral est nommé par la Cour à moins que, les parties n’aient prévu que les arbitres qu’elles ont désignés devraient faire choix du troisième arbitre dans un délai déterminé. Dans ce dernier cas, il appartient à la Cour de confirmer le troisième arbitre. Si, à l’expiration du délai fixé par les parties ou imparti par la Cour, les arbitres désignés par les parties n’ont pu se mettre d’accord, le troisième arbitre est nommé par la Cour.",
                    style: false
                  },
                  {
                    value:
                      "Si les parties n’ont pas fixé d’un commun accord le nombre des arbitres, la Cour nomme un arbitre unique à moins que, le différend ne lui paraisse justifier la désignation de trois arbitres. Dans ce dernier cas, les parties disposeront d’un délai de quinze jours pour procéder à la désignation des arbitres.",
                    style: false
                  },
                  {
                    value:
                      "Les arbitres peuvent être choisis sur la liste des arbitres établie par la Cour et mise à jour annuellement. Les membres de la Cour ne peuvent pas être inscrits sur cette liste.",
                    style: false
                  },
                  {
                    value: "En cas de récusation d’un arbitre par une partie, la Cour statue. Sa décision n’est pas susceptible de recours.",
                    style: false
                  },
                  {
                    value:
                      "Il y a lieu à remplacement d’un arbitre lorsqu’il est décédé ou empêché, lorsqu’il doit se démettre de ses fonctions à la suite d’une récusation ou pour tout autre motif ou, lorsque la Cour, après avoir recueilli ses observations, constate qu’il ne remplit pas ses fonctions conformément aux stipulations du présent titre ou du règlement d’arbitrage ou, dans les délais impartis. Dans chacun de ces cas, il est procédé conformément aux deuxième et troisième alinéas.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 23",
                paragraphs: [
                  {
                    value:
                      "Tout tribunal d’un État-Partie saisi d’un litige que les parties étaient convenues de soumettre à l’arbitrage se déclarera incompétent si l’une des parties le demande et, renverra le cas échéant à la procédure d’arbitrage prévue au présent Traité.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 24",
                paragraphs: [
                  {
                    value: "Avant de signer une sentence partielle ou définitive, l’arbitre doit en soumettre le projet à la Cour Commune de Justice et d’Arbitrage.",
                    style: false
                  },
                  {
                    value: "Celle-ci ne peut proposer que des modifications de pure forme.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 25",
                paragraphs: [
                  {
                    value:
                      "Les sentences arbitrales rendues conformément aux stipulations du présent titre ont l’autorité définitive de la chose jugée sur le territoire de chaque État-Partie au même titre que les décisions rendues par les juridictions de l’État. Elles peuvent faire l’objet d’une exécution forcée en vertu d’une décision d’exequatur.",
                    style: false
                  },
                  {
                    value:
                      "La Cour Commune de Justice et d’Arbitrage a seule compétence pour rendre une telle décision. L’exequatur ne peut être refusé que dans les cas suivants :",
                    style: false
                  },
                  {
                    value: "- si l’arbitre a statué sans convention d’arbitrage ou sur une convention nulle ou expirée ;",
                    style: "ml-8"
                  },
                  {
                    value: "- si l’arbitre a statué sans se conformer à la mission qui lui avait été conférée ;",
                    style: "ml-8"
                  },
                  {
                    value: "- lorsque le principe de la procédure contradictoire n’a pas été respecté ;",
                    style: "ml-8"
                  },
                  {
                    value: "- si la sentence est contraire à l’ordre public international.",
                    style: "ml-8"
                  }
                ]
              },
              {
                name: "Article 26",
                paragraphs: [
                  {
                    value:
                      "Le Règlement d’arbitrage de la Cour Commune de Justice et d’Arbitrage est fixé par le Conseil des Ministres dans les conditions prévues à l’article 8 ci-dessus. II est publié au Journal Officiel de l’OHADA. Il est également publié au Journal Officiel des États-Parties ou par tout autre moyen approprié.",
                    style: false
                  }
                ]
              }
            ]
          },
          {
            name: "Titre V : Les Institutions",
            headerThreeList: [
              {
                name: "Article 27",
                paragraphs: [
                  {
                    value: "Le Conseil des Ministres est composé des Ministres chargés de la Justice et des Ministres chargés des Finances.",
                    style: false
                  },
                  {
                    value:
                      "La présidence est exercée à tour de rôle par chaque État-Partie pour une durée d’un an, dans l’ordre suivant : Bénin, Burkina Faso, Cameroun, Centrafrique, Comores, Congo, Côte d’Ivoire, Gabon, Guinée Équatoriale, Mali, Niger, Sénégal, Tchad, Togo.",
                    style: false
                  },
                  {
                    value:
                      "Si un État-Partie ne peut exercer la présidence du Conseil des Ministres pendant l’année où elle lui revient, le Conseil désigne pour exercer cette présidence, l’État venant immédiatement après dans l’ordre prévu ci-dessus.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 28",
                paragraphs: [
                  {
                    value:
                      "Le Conseil des Ministres se réunit au moins une fois par an sur convocation de son Président, à l’initiative de celui-ci ou, du tiers des États-Parties. Il ne peut valablement délibérer que si les deux tiers au moins des États-Parties sont représentés.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 29",
                paragraphs: [
                  {
                    value: "Le Président du Conseil des Ministres arrête l’ordre du jour du Conseil sur la proposition du Secrétaire Permanent.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 30",
                paragraphs: [
                  {
                    value:
                      "Les décisions du Conseil des Ministres autres que celles prévues à l’article 8 ci-dessus sont prises à la majorité absolue des États-Parties présents et votants. Chacun des États dispose d’une voix.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 31",
                paragraphs: [
                  {
                    value:
                      "La Cour Commune de Justice et d’Arbitrage est composée de sept juges élus pour sept ans renouvelables une fois, parmi les ressortissants des États-Parties dans les fonctions et sous les conditions suivantes :",
                    style: false
                  },
                  {
                    value: "1. les magistrats ayant acquis une expérience judiciaire d’au moins quinze années et exercé de hautes fonctions juridictionnelles ;",
                    style: "ml-8"
                  },
                  {
                    value: "2. les avocats inscrits au Barreau de l’un des États-Parties ayant au moins quinze années d’expérience professionnelle ;",
                    style: "ml-8"
                  },
                  {
                    value: "3. les professeurs de droit ayant au moins quinze années d’expérience professionnelle.",
                    style: "ml-8"
                  },
                  {
                    value: "Seuls deux membres de la Cour peuvent appartenir aux catégories visées aux paragraphes 2 et 3 ci-dessus.",
                    style: false
                  },
                  {
                    value: "La Cour est renouvelée par septième chaque année.",
                    style: false
                  },
                  {
                    value: "La Cour ne peut comprendre plus d’un ressortissant du même État.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 32",
                paragraphs: [
                  {
                    value:
                      "Les membres de la Cour sont élus au scrutin secret par le Conseil des Ministres sur une liste de personnes présentées à cet effet par les États-Parties.",
                    style: false
                  },
                  {
                    value: "Chaque État-Partie peut présenter deux candidats au plus.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 33",
                paragraphs: [
                  {
                    value:
                      "Le Secrétaire Permanent invite les États-Parties à procéder, dans un délai d’au moins, quatre mois avant les élections, à la présentation des candidats à la Cour.",
                    style: false
                  },
                  {
                    value:
                      "Le Secrétaire Permanent dresse la liste alphabétique des personnes ainsi présentées et la communique un mois au moins avant les élections aux États-Parties.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 34",
                paragraphs: [
                  {
                    value: "Après leur élection, les membres de la Cour font la déclaration solennelle de bien et fidèlement remplir leurs fonctions en toute impartialité.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 35",
                paragraphs: [
                  {
                    value:
                      "En cas de décès d’un membre de la Cour, le Président de la Cour en informe immédiatement le Secrétaire Permanent qui, déclare le siège vacant à partir de la date du décès.",
                    style: false
                  },
                  {
                    value:
                      "En cas de démission d’un membre de la Cour ou si, de l’avis unanime des autres membres de la Cour, un membre a cessé de remplir ses fonctions pour toute autre cause qu’une absence de caractère temporaire ou, n’est plus en mesure de les remplir, le Président de la Cour, après avoir invité l’intéressé à présenter à la Cour ses observations orales en informe le Secrétaire Permanent qui, déclare alors le siège vacant.",
                    style: false
                  },
                  {
                    value:
                      "Dans chacun des cas prévus ci-dessus, Le Conseil des Ministres procède, dans les conditions prévues aux articles 32 et 33 ci-dessus, au remplacement du membre dont le siège est devenu vacant, pour la fraction du mandat restant à courir sauf si cette fraction est inférieure à six mois.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 36",
                paragraphs: [
                  {
                    value: "Les membres de la Cour sont inamovibles.",
                    style: false
                  },
                  {
                    value: "Tout membre de la Cour conserve son mandat jusqu’à la date d’entrée en fonction de son successeur.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 37",
                paragraphs: [
                  {
                    value:
                      "La Cour élit en son sein, pour une durée de trois ans et demi non renouvelable, son Président et ses deux Vice-présidents. Les membres de la Cour dont le mandat restant à courir à la date de l’élection est inférieur à cette durée peuvent être élus pour exercer ces fonctions jusqu’à l’expiration dudit mandat. Ils peuvent être renouvelés dans ces fonctions s’ils sont élus par le Conseil des Ministres pour exercer un nouveau mandat de membre de la Cour. Aucun membre de la Cour ne peut exercer des fonctions politiques ou administratives. L’exercice de toute activité rémunérée doit être autorisé par la Cour.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 38",
                paragraphs: [
                  {
                    value:
                      "La durée du mandat des sept juges nommés simultanément pour la constitution initiale de la Cour sera respectivement de trois ans, quatre ans, cinq ans, six ans, sept ans, huit ans et neuf ans. Elle sera déterminée pour chacun d’eux par tirage au sort effectué en Conseil des Ministres par le Président du Conseil. Le premier renouvellement de la Cour aura lieu trois ans après la constitution initiale de celle-ci.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 39",
                paragraphs: [
                  {
                    value:
                      "Le Président de la Cour Commune de Justice et d’Arbitrage nomme le Greffier en chef de la Cour, après avis de celle-ci, parmi les Greffiers en chefs ayant exercé leurs fonctions pendant au moins quinze ans et présentés par les États-Parties.",
                    style: false
                  },
                  {
                    value: "Il pourvoit, sur proposition du Greffier en chef, aux autres emplois de la Cour. Le secrétariat de la Cour est assuré par le greffier en chef.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 40",
                paragraphs: [
                  {
                    value: "Le Secrétaire Permanent est nommé par le Conseil des Ministres pour une durée de quatre ans renouvelables une fois.",
                    style: false
                  },
                  {
                    value:
                      "Il nomme ses collaborateurs conformément aux critères de recrutement définis par le Conseil des Ministres et dans la limite des effectifs prévus au budget.",
                    style: false
                  },
                  {
                    value: "Il dirige le Secrétariat Permanent.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 41",
                paragraphs: [
                  {
                    value:
                      "Il est institué une École Régionale Supérieure de la Magistrature qui concourt à la formation et au perfectionnement des magistrats et des auxiliaires de justice des États-Parties.",
                    style: false
                  },
                  {
                    value: "Le Directeur de l’École est nommé par le Conseil des Ministres.",
                    style: false
                  },
                  {
                    value:
                      "L’organisation, le fonctionnement, les ressources et les prestations de l’École sont définis par un règlement du Conseil des Ministres pris sur le rapport du Directeur de l’École.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 42",
                paragraphs: [
                  {
                    value: "Le français est la langue de travail de l’OHADA.",
                    style: false
                  }
                ]
              }
            ]
          },
          {
            name: "Titre VI : Dispositions financières",
            headerThreeList: [
              {
                name: "Article 43",
                paragraphs: [
                  {
                    value: "Les ressources de l’OHADA sont composées notamment :",
                    style: false
                  },
                  {
                    value: "1. des cotisations annuelles des États-Parties ;",
                    style: "ml-8"
                  },
                  {
                    value: "2. des concours prévus par les conventions conclues par l’OHADA avec des États ou des organisations internationales ;",
                    style: "ml-8"
                  },
                  {
                    value: "3. de dons et legs.",
                    style: "ml-8"
                  },
                  {
                    value:
                      "Les cotisations annuelles des États-Parties sont arrêtées par le Conseil des Ministres. Le Conseil des Ministres approuve les conventions prévues au paragraphe b) et accepte les dons et legs prévus au paragraphe c).",
                    style: false
                  }
                ]
              },
              {
                name: "Article 44",
                paragraphs: [
                  {
                    value:
                      "Le barème des tarifs de la procédure d’arbitrage instituée par le présent Traité ainsi que la répartition des recettes correspondantes sont approuvés par le Conseil des Ministres.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 45",
                paragraphs: [
                  {
                    value: "Les budgets annuels de la Cour Commune de Justice et d’Arbitrage et du Secrétariat Permanent sont adoptés par le Conseil des Ministres.",
                    style: false
                  },
                  {
                    value:
                      "Les comptes de l’exercice clos sont certifiés par des commissaires au compte désignés par le Conseil des Ministres. Ils sont approuvés par le Conseil des Ministres.",
                    style: false
                  }
                ]
              }
            ]
          },
          {
            name: "Titre VII : Statut, immunités et privilèges",
            headerThreeList: [
              {
                name: "Article 46",
                paragraphs: [
                  {
                    value: "L’OHADA a la pleine personnalité juridique internationale. Elle a en particulier la capacité :",
                    style: false
                  },
                  {
                    value: "1. de contracter ;",
                    style: "ml-8"
                  },
                  {
                    value: "2. d’acquérir des biens meubles et immeubles et d’en disposer ;",
                    style: "ml-8"
                  },
                  {
                    value: "3. d’ester en justice.",
                    style: "ml-8"
                  }
                ]
              },
              {
                name: "Article 47",
                paragraphs: [
                  {
                    value: "Afin de pouvoir remplir ses fonctions, l’OHADA jouit sur le territoire de chaque État-Partie des immunités et privilèges prévus au présent titre.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 48",
                paragraphs: [
                  {
                    value: "L’OHADA, ses biens et ses avoirs ne peuvent faire l’objet d’aucune action judiciaire sauf, si elle renonce à cette immunité.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 49",
                paragraphs: [
                  {
                    value:
                      "Les fonctionnaires et employés du Secrétariat Permanent, de l’École Régionale Supérieure de la Magistrature et de la Cour Commune de Justice et d’Arbitrage ainsi que, les juges de la Cour et les arbitres désignés par cette dernière jouissent dans l’exercice de leurs fonctions des privilèges et immunités diplomatiques. Les juges ne peuvent en outre être poursuivis pour des actes accomplis en dehors de l’exercice de leurs fonctions qu’avec l’autorisation de la Cour.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 50",
                paragraphs: [
                  {
                    value: "Les archives de l’OHADA sont inviolables où qu’elles se trouvent.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 51",
                paragraphs: [
                  {
                    value:
                      "L’OHADA, ses avoirs, ses biens et ses revenus ainsi que les opérations autorisées par le présent Traité sont exonérés de tous impôts, taxes et droits de douane. L’OHADA est également exempté de toute obligation relative au recouvrement ou au paiement d’impôts, de taxes ou de droits de douane.",
                    style: false
                  }
                ]
              }
            ]
          },
          {
            name: "Titre VIII : Clauses protocolaires",
            headerThreeList: [
              {
                name: "Article 52",
                paragraphs: [
                  {
                    value: "Le présent Traité est soumis à la ratification des États signataires conformément à leurs procédures constitutionnelles.",
                    style: false
                  },
                  {
                    value:
                      "Le présent Traité entrera en vigueur soixante jours après la date du dépôt du septième instrument de ratification. Toutefois, si la date du dépôt du septième instrument de ratification est antérieure au cent quatre-vingtième jours qui suit le jour de la signature du Traité, le Traité entrera en vigueur le deux cent quarantième jour suivant la date de sa signature.",
                    style: false
                  },
                  {
                    value:
                      "A l’égard de tout État signataire déposant ultérieurement son instrument de ratification, le Traité et les Actes Uniformes adoptés avant la ratification entreront en vigueur soixante jours après la date dudit dépôt.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 53",
                paragraphs: [
                  {
                    value:
                      "Le présent Traité est, dès son entrée en vigueur, ouvert à l’adhésion de tout État membre de l’OUA et non signataire du Traité. Il est également ouvert à l’adhésion de tout autre État non membre de l’OUA invité à y adhérer du commun accord de tous les États-Parties.",
                    style: false
                  },
                  {
                    value:
                      "A l’égard de tout État adhérent, le présent Traité et les Actes Uniformes adoptés avant l’adhésion entreront en vigueur soixante jours après la date du dépôt de l’instrument d’adhésion.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 54",
                paragraphs: [
                  {
                    value: "Aucune réserve n’est admise au présent Traité.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 55",
                paragraphs: [
                  {
                    value:
                      "Dès l’entrée en vigueur du Traité, les Institutions communes prévues aux articles 27 à 41 ci-dessus seront mises en place. Les États signataires du Traité ne l’ayant pas encore ratifié pourront en outre siéger au Conseil des Ministres en qualité d’observateurs sans droit de vote.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 56",
                paragraphs: [
                  {
                    value:
                      "Tout différend qui pourrait surgir entre les États-Parties quant à l’interprétation ou à l’application du présent Traité et qui ne serait pas résolu à l’amiable peut être porté par un État-Partie devant la Cour Commune de Justice et d’Arbitrage.",
                    style: false
                  },
                  {
                    value:
                      "Si la Cour compte sur le siège un juge de la nationalité d’une des parties, toute autre partie peut désigner un juge ad hoc pour siéger dans l’affaire. Ce dernier devra remplir les conditions fixées à l’article 31 ci-dessus.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 57",
                paragraphs: [
                  {
                    value: "Les instruments de ratification et les instruments d’adhésion seront déposés auprès du gouvernement du Sénégal qui, sera le gouvernement dépositaire.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 58",
                paragraphs: [
                  {
                    value:
                      "Tout État ratifiant le présent Traité ou y adhérant postérieurement à l’entrée en vigueur d’un amendement au présent Traité devient par là-même partie au Traité tel qu’amendé.",
                    style: false
                  },
                  {
                    value:
                      "Le Conseil des Ministres ajoute le nom de l’État adhérent sur la liste prévue avant le nom de l’État qui assure la présidence du Conseil des Ministres à la date de l’adhésion.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 59",
                paragraphs: [
                  {
                    value:
                      "Le gouvernement dépositaire enregistrera le Traité auprès du Secrétariat de l’OUA et auprès du Secrétariat des Nations-Unies conformément à l’article 102 de la Charte des Nations-Unies.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 60",
                paragraphs: [
                  {
                    value: "Le gouvernement dépositaire avisera sans délai tous les États signataires ou adhérents :",
                    style: false
                  },
                  {
                    value: "1. des dates de signature,",
                    style: "ml-8"
                  },
                  {
                    value: "2. des dates d’enregistrement du Traité ;",
                    style: "ml-8"
                  },
                  {
                    value: "3. des dates de dépôt des instruments de ratification et d’adhésion ;",
                    style: "ml-8"
                  },
                  {
                    value: "4. de la date d’entrée en vigueur du Traité.",
                    style: "ml-8"
                  }
                ]
              }
            ]
          },
          {
            name: "Titre IX : Révision et Dénonciation",
            headerThreeList: [
              {
                name: "Article 61",
                paragraphs: [
                  {
                    value:
                      "Le présent Traité peut être amendé ou révisé si un État-Partie envoie à cet effet une demande écrite au Secrétariat Permanent de l’OHADA. L’amendement ou la révision doit être adopté dans les mêmes formes que le Traité.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 62",
                paragraphs: [
                  {
                    value:
                      "Le présent Traité a une durée illimitée. Il ne peut, en tout état de cause, être dénoncé avant dix années à partir de la date de son entrée en vigueur.",
                    style: false
                  },
                  {
                    value:
                      "Toute dénonciation du présent Traité doit être notifiée au gouvernement dépositaire et ne produira d’effet qu’une année après la date de cette notification.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 63",
                paragraphs: [
                  {
                    value:
                      "Le présent Traité, rédigé en deux exemplaires, en langue française, sera déposé dans les archives du gouvernement de la République du Sénégal qui remettra une copie certifiée à chacun des autres États-Parties signataires.",
                    style: false
                  },
                  {
                    value: "En foi de quoi les Chefs d’État et plénipotentiaires soussignés ont apposé leur signature au bas du présent Traité.",
                    style: false
                  },
                  {
                    value: "Fait à Port-Louis, le 17 octobre 1993",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République du Bénin",
                    style: false
                  },
                  {
                    value: "Monsieur Nicéphore SOGLO",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président du Burkina Faso",
                    style: false
                  },
                  {
                    value: "Monsieur Blaise COMPAORE",
                    style: "font-bold"
                  },
                  {
                    value: "Pour Le Président de la République du Cameroun",
                    style: false
                  },
                  {
                    value: "Le Ministre des Relations Extérieures",
                    style: false
                  },
                  {
                    value: "Le Président de la République Centrafricaine",
                    style: false
                  },
                  {
                    value: "Le Président de la République Fédérale Islamique des COMORES,",
                    style: false
                  },
                  {
                    value: "Monsieur SAID MOHAMED DJOLHAR",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République du Congo,",
                    style: false
                  },
                  {
                    value: "Pascal LISSOUBA",
                    style: "font-bold"
                  },
                  {
                    value: "Pour le Président de la République de Côte d’Ivoire,",
                    style: false
                  },
                  {
                    value: "Monsieur ALASSANE DRAMANE OUATTARA",
                    style: "font-bold"
                  },
                  {
                    value: "Premier Ministre",
                    style: false
                  },
                  {
                    value: "Pour le Président de la République Gabonaise,",
                    style: false
                  },
                  {
                    value: "Monsieur Casimir OYE MBA",
                    style: "font-bold"
                  },
                  {
                    value: "Premier Ministre",
                    style: false
                  },
                  {
                    value: "Le Président de la République de Guinée Équatoriale,",
                    style: false
                  },
                  {
                    value: "Général Teodoro OBIANG NGUEMA MBASOGO",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République du Mali,",
                    style: false
                  },
                  {
                    value: "Monsieur Alpha OUMAR KONARE",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République du Niger,",
                    style: false
                  },
                  {
                    value: "Monsieur Mahamane OUSMANE",
                    style: "font-bold"
                  },
                  {
                    value: "Pour le Président de la République du Sénégal,",
                    style: false
                  },
                  {
                    value: "Monsieur Moustapha NIASSE",
                    style: "font-bold"
                  },
                  {
                    value: "Ministre d’État, des Affaires Étrangères et des Sénégalais de l’Extérieur",
                    style: false
                  },
                  {
                    value: "Le Président de la République du Tchad,",
                    style: false
                  },
                  {
                    value: "Colonel Idriss DEBY",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République Togolaise,",
                    style: false
                  },
                  {
                    value: "Gnassingbé EYADEMA",
                    style: "font-bold"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        name: "TRAITÉ PORTANT RÉVISION DU TRAITÉ RELATIF À L’HARMONISATION DU DROIT DES AFFAIRES EN AFRIQUE, SIGNÉ A PORT-LOUIS (ILE MAURICE), LE 17 OCTOBRE 1993\u200c\u200c",
        info: "(Journal Officiel N°20 du 1er novembre 2009)",
        headerTwoList: [
          {
            name: "PRÉAMBULE",
            headerThreeList: [
              {
                name: "",
                paragraphs: [
                  {
                    value: "Le Président de la République du BÉNIN,",
                    style: false
                  },
                  {
                    value: "Le Président du BURKINA FASO,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du CAMEROUN,",
                    style: false
                  },
                  {
                    value: "Le Président de la République CENTRAFRICAINE,",
                    style: false
                  },
                  {
                    value: "Le Président de l’Union des COMORES,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du CONGO,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du CÔTE D’IVOIRE,",
                    style: false
                  },
                  {
                    value: "Le Président de la République GABONAISE,",
                    style: false
                  },
                  {
                    value: "Le Président de la République de GUINÉE,",
                    style: false
                  },
                  {
                    value: "Le Président de la République de GUINÉE BISSAU,",
                    style: false
                  },
                  {
                    value: "Le Président de la République de GUINÉE ÉQUATORIALE,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du MALI,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du NIGER,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du SÉNÉGAL,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du TCHAD,",
                    style: false
                  },
                  {
                    value: "Le Président de la République TOGOLAISE,",
                    style: false
                  },
                  {
                    value: "Hautes parties contractantes du Traité,",
                    style: false
                  },
                  {
                    value: "Réaffirmant leur détermination à accomplir de nouveaux progrès sur la voie de l’unité africaine",
                    style: false
                  },
                  {
                    value:
                      "et leur volonté de renforcer la sécurité juridique et judiciaire dans l’espace de l’Organisation pour l’Harmonisation en Afrique du Droit des Affaires (OHADA), de nature à garantir un climat de confiance concourant à faire de l’Afrique un pôle de développement ;",
                    style: false
                  },
                  {
                    value:
                      "Résolus à faire de l’harmonisation du droit des affaires un outil d’affermissement continu de l’État de droit et de l’intégration juridique et économique ;",
                    style: false
                  },
                  {
                    value: "Décidés à créer toutes les conditions nécessaires à la consolidation des acquis de l’OHADA et à leur amplification et promotion ;",
                    style: false
                  },
                  {
                    value:
                      "Conviennent de modifier et de compléter le Traité relatif à l’harmonisation du Droit des affaires en Afrique, signé à Port-Louis (Ile Maurice) le 17 octobre 1993 :",
                    style: false
                  }
                ]
              },
              {
                name: "Article Premier",
                paragraphs: [
                  {
                    value: "Les articles 3, 4, 7, 9, 12, 14, 17, 27, 31, 39, 40, 41, 42, 43, 45, 49, 57, 59, 61 et 63 du Traité",
                    style: false
                  },
                  {
                    value:
                      "relatif à l’harmonisation du droit des affaires en Afrique, signé à Port-Louis (Ile Maurice), le 17 octobre 1993, sont modifiés et complétés ainsi qu’il suit :",
                    style: false
                  }
                ]
              },
              {
                name: "Article 3",
                paragraphs: [
                  {
                    value:
                      "La réalisation des tâches prévues au présent Traité est assurée par une organisation dénommée Organisation pour l’Harmonisation en Afrique du Droit des Affaires (OHADA).",
                    style: false
                  },
                  {
                    value:
                      "L’OHADA comprend la Conférence des Chefs d’État et de Gouvernement, le Conseil des Ministres, la Cour Commune de Justice et d’Arbitrage et le Secrétariat Permanent.",
                    style: false
                  },
                  {
                    value:
                      "Le siège de l’OHADA est fixé à Yaoundé en République du Cameroun. Il peut être transféré en tout autre lieu sur décision de la Conférence des Chefs d’État et de Gouvernement.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 4",
                paragraphs: [
                  {
                    value:
                      "Des règlements pour l’application du présent Traité et des décisions seront pris, chaque fois que de besoin, par le Conseil des Ministres, à la majorité absolue.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 7",
                paragraphs: [
                  {
                    value:
                      "Les projets d’Actes Uniformes sont communiqués par le Secrétariat Permanent aux Gouvernements des États-Parties, qui disposent d’un délai de quatre-vingt-dix jours à compter de la date de la réception de cette communication pour faire parvenir au Secrétariat Permanent leurs observations écrites.",
                    style: false
                  },
                  {
                    value:
                      "Toutefois, le délai prévu à l’alinéa premier peut être prorogé d’une durée équivalente en fonction des circonstances et de la nature du texte à adopter, à la diligence du Secrétariat Permanent.",
                    style: false
                  },
                  {
                    value:
                      "A l’expiration de ce délai, le projet d’Acte Uniforme, accompagné des observations des États- Parties et d’un rapport du Secrétariat Permanent, est immédiatement transmis pour avis par ce dernier à la Cour Commune de Justice et d’Arbitrage. La Cour donne son avis dans un délai de soixante jours à compter de la date de la réception de la demande de consultation.",
                    style: false
                  },
                  {
                    value:
                      "A l’expiration de ce nouveau délai, le Secrétariat Permanent met au point le texte définitif du projet d’Acte Uniforme, dont il propose l’inscription à l’ordre du jour du prochain Conseil des Ministres.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 9",
                paragraphs: [
                  {
                    value:
                      "Les Actes Uniformes sont publiés au Journal Officiel de l’OHADA par le Secrétariat Permanent dans les soixante jours suivant leur adoption. Ils sont applicables quatre-vingt-dix jours après cette publication, sauf modalités particulières d’entrée en vigueur prévues par les Actes Uniformes.",
                    style: false
                  },
                  {
                    value:
                      "Ils sont également publiés dans les États-Parties, au Journal Officiel ou par tout autre moyen approprié. Cette formalité n’a aucune incidence sur l’entrée en vigueur des Actes Uniformes.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 12",
                paragraphs: [
                  {
                    value: "Les Actes Uniformes peuvent être modifiés, à la demande de tout État-Partie ou du Secrétariat Permanent, après autorisation du Conseil des Ministres.",
                    style: false
                  },
                  {
                    value: "La modification intervient dans les conditions prévues par les articles 6 à 9 ci-dessus.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 14",
                paragraphs: [
                  {
                    value:
                      "La Cour Commune de Justice et d’Arbitrage assure l’interprétation et l’application communes du Traité ainsi que des règlements pris pour son application, des Actes Uniformes et des décisions.",
                    style: false
                  },
                  {
                    value:
                      "La Cour peut être consultée par tout État-Partie ou par le Conseil des Ministres sur toute question entrant dans le champ de l’alinéa précédent. La même faculté de solliciter l’avis consultatif de la Cour est reconnue aux juridictions nationales saisies en application de l’article 13 ci-dessus.",
                    style: false
                  },
                  {
                    value:
                      "Saisie par la voie du recours en cassation, la Cour se prononce sur les décisions rendues par les juridictions d’Appel des États-Parties dans toutes les affaires soulevant des questions relatives à l’application des Actes Uniformes et des règlements prévus au présent Traité à l’exception des décisions appliquant des sanctions pénales.",
                    style: false
                  },
                  {
                    value:
                      "Elle se prononce dans les mêmes conditions sur les décisions non susceptibles d’appel rendues par toute juridiction des États-Parties dans les mêmes contentieux.",
                    style: false
                  },
                  {
                    value: "En cas de cassation, elle évoque et statue sur le fond.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 17",
                paragraphs: [
                  {
                    value: "L’incompétence manifeste de la Cour Commune de Justice et d’Arbitrage peut être soulevée d’office ou par toute partie au litige in limine litis.",
                    style: false
                  },
                  {
                    value:
                      "La Cour se prononce dans les trente jours qui suivent la date de réception des observations de la partie adverse ou celle d’expiration du délai imparti pour la présentation desdites observations.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 27",
                paragraphs: [
                  {
                    value:
                      "1. La Conférence des Chefs d’État et de Gouvernement est composée des Chefs d’État et de Gouvernement des États-Parties. Elle est présidée par le Chef de l’État ou de Gouvernement dont le pays assure la présidence du Conseil des Ministres.",
                    style: "ml-8"
                  },
                  {
                    value: "2. Le Conseil des Ministres est composé des Ministres chargés de la Justice et des Finances des États-Parties.",
                    style: "ml-8"
                  },
                  {
                    value: "La présidence du Conseil des Ministres est exercée à tour de rôle et par ordre alphabétique, pour une durée d’un an, par chaque État-Partie.",
                    style: false
                  },
                  {
                    value: "Le Président du Conseil des Ministres est assisté par le Secrétaire Permanent.",
                    style: false
                  },
                  {
                    value:
                      "Les États adhérents assurent pour la première fois la présidence du Conseil des Ministres dans l’ordre de leur adhésion, après le tour des pays signataires du Traité.",
                    style: false
                  },
                  {
                    value:
                      "Si un État-Partie ne peut exercer la présidence du Conseil des Ministres pendant l’année où elle lui revient, le Conseil désigne, pour exercer cette présidence, l’État venant immédiatement après, dans l’ordre prévu aux alinéas précédents.",
                    style: false
                  },
                  {
                    value:
                      "Toutefois, l’État précédemment empêché qui estime être en mesure d’assurer la présidence en saisit, en temps utile, le Secrétaire Permanent, pour décision à prendre par le Conseil des Ministres.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 31",
                paragraphs: [
                  {
                    value: "La Cour Commune de Justice et d’Arbitrage est composée de neuf juges.",
                    style: false
                  },
                  {
                    value:
                      "Toutefois le Conseil des Ministres peut, compte tenu des nécessités de service et des possibilités financières, fixer un nombre de juges supérieur à celui prévu à l’alinéa précédent.",
                    style: false
                  },
                  {
                    value:
                      "Les Juges de la Cour Commune de Justice et d’Arbitrage sont élus pour un mandat de sept ans non renouvelable, parmi les ressortissants des États-Parties. Ils sont choisis parmi :",
                    style: false
                  },
                  {
                    value:
                      "1. les magistrats ayant acquis une expérience professionnelle d’au moins quinze années et réunissant les conditions requises pour l’exercice dans leurs pays respectifs de hautes fonctions judiciaires ;",
                    style: "ml-8"
                  },
                  {
                    value: "2. les avocats inscrits au Barreau de l’un des États-Parties, ayant au moins quinze années d’expérience professionnelle",
                    style: "ml-8"
                  },
                  {
                    value: "3. les professeurs de Droit ayant au moins quinze années d’expérience professionnelle.",
                    style: "ml-8"
                  },
                  {
                    value: "Un tiers des membres de la Cour doit appartenir aux catégories visées aux points 2 et 3 de l’alinéa précédent.",
                    style: false
                  },
                  {
                    value: "La Cour ne peut comprendre plus d’un ressortissant du même État.",
                    style: false
                  },
                  {
                    value: "Les modalités d’application du présent article seront précisées par le Règlement prévu à l’article 19 ci-dessus.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 39",
                paragraphs: [
                  {
                    value:
                      "Le Président de la Cour Commune de Justice et d’Arbitrage nomme le Greffier en chef de la Cour après avis de celle-ci, parmi les Greffiers en chef ayant exercé leurs fonctions pendant au moins quinze ans et présentés par les États-Parties.",
                    style: false
                  },
                  {
                    value:
                      "Après avis de la Cour, le Président nomme également le Secrétaire général chargé d’assister celle-ci dans l’exercice de ses attributions d’administration de l’arbitrage, selon les critères définis par un règlement du Conseil des Ministres.",
                    style: false
                  },
                  {
                    value: "Il pourvoit, sur proposition, selon les cas, du Greffier en chef ou du Secrétaire général, aux autres emplois.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 40",
                paragraphs: [
                  {
                    value:
                      "Le Secrétariat Permanent est l’organe exécutif de l’OHADA. Il est dirigé par un Secrétaire Permanent nommé par le Conseil des Ministres pour un mandat de quatre ans renouvelable une fois.",
                    style: false
                  },
                  {
                    value: "Le Secrétaire Permanent représente l’OHADA. Il assiste le Conseil des Ministres.",
                    style: false
                  },
                  {
                    value:
                      "La nomination et les attributions du Secrétaire Permanent ainsi que l’organisation et le fonctionnement du Secrétariat Permanent sont définis par un règlement du Conseil des Ministres.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 41",
                paragraphs: [
                  {
                    value:
                      "Il est institué un établissement de formation, de perfectionnement et de recherche en droit des affaires dénommé École Régionale Supérieure de la Magistrature (ERSUMA).",
                    style: false
                  },
                  {
                    value: "L’établissement est rattaché au Secrétariat Permanent.",
                    style: false
                  },
                  {
                    value: "La dénomination et l’orientation de l’établissement peuvent être changées par un règlement du Conseil des Ministres.",
                    style: false
                  },
                  {
                    value: "L’établissement est dirigé par un Directeur Général nommé par le Conseil des Ministres pour un mandat de quatre ans renouvelable une fois.",
                    style: false
                  },
                  {
                    value: "L’organisation, le fonctionnement, les ressources et les prestations de l’établissement sont définis par un règlement du Conseil des Ministres.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 42",
                paragraphs: [
                  {
                    value: "Les langues de travail de l’OHADA sont : le français, l’anglais, l’espagnol et le portugais.",
                    style: false
                  },
                  {
                    value:
                      "Avant traduction dans les autres langues, les documents déjà publiés en français produisent tous leurs effets. En cas de divergence entre les différentes traductions, la version française fait foi.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 43",
                paragraphs: [
                  {
                    value: "Les ressources de l’OHADA sont composées notamment :",
                    style: false
                  },
                  {
                    value: "- des contributions annuelles des États-Parties dont les modalités sont définies par un règlement du Conseil des Ministres ;",
                    style: "ml-8"
                  },
                  {
                    value: "- des concours prévus par les conventions conclues par l’OHADA avec des États ou des organisations internationales ;",
                    style: "ml-8"
                  },
                  {
                    value: "- de dons et legs.",
                    style: "ml-8"
                  },
                  {
                    value: "Les contributions annuelles des États-Parties sont arrêtées par le Conseil des Ministres.",
                    style: false
                  },
                  {
                    value: "Le Conseil des Ministres approuve les conventions prévues au paragraphe b et accepte les dons et legs prévus au paragraphe c.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 45",
                paragraphs: [
                  {
                    value: "Le budget annuel de l’OHADA est adopté par le Conseil des Ministres.",
                    style: false
                  },
                  {
                    value:
                      "Les comptes de l’exercice clos sont certifiés par des commissaires aux comptes désignés par le Conseil des Ministres. Ils sont approuvés par le Conseil des Ministres.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 49",
                paragraphs: [
                  {
                    value:
                      "Dans les conditions déterminées par un Règlement, les fonctionnaires et employés de l’OHADA, les juges de la Cour Commune de Justice et d’Arbitrage ainsi que les arbitres nommés ou confirmés par cette dernière jouissent dans l’exercice de leurs fonctions des privilèges et immunités diplomatiques.",
                    style: false
                  },
                  {
                    value: "Les immunités et privilèges mentionnés ci-dessus peuvent être, selon les circonstances, levés par le Conseil des Ministres.",
                    style: false
                  },
                  {
                    value: "En outre, les juges ne peuvent être poursuivis pour des actes accomplis en dehors de l’exercice de leurs fonctions qu’avec l’autorisation de la Cour.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 57",
                paragraphs: [
                  {
                    value:
                      "Les instruments de ratification et les instruments d’adhésion seront déposés auprès du Gouvernement du Sénégal qui sera le Gouvernement dépositaire. Copie en sera délivrée au Secrétariat Permanent par ce dernier.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 59",
                paragraphs: [
                  {
                    value:
                      "Le Gouvernement dépositaire enregistrera le Traité auprès de l’Union Africaine et auprès de l’Organisation des Nations Unies conformément à l’article 102 de la Charte des Nations Unies.",
                    style: false
                  },
                  {
                    value: "Une copie du Traité enregistré sera délivrée au Secrétariat Permanent par le Gouvernement dépositaire.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 61",
                paragraphs: [
                  {
                    value:
                      "Le Traité peut être amendé ou révisé si un État-Partie envoie, à cet effet, une demande écrite au Secrétariat Permanent de l’OHADA qui en saisit le Conseil des Ministres.",
                    style: false
                  },
                  {
                    value: "Le Conseil des Ministres apprécie l’objet de la demande et l’étendue de la modification.",
                    style: false
                  },
                  {
                    value: "L’amendement ou la révision doit être adopté dans les mêmes formes que le Traité à la diligence du Conseil des Ministres.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 63",
                paragraphs: [
                  {
                    value:
                      "Le Traité, rédigé en deux exemplaires en langues française, anglaise, espagnole et portugaise, sera déposé dans les archives du Gouvernement de la République du Sénégal qui remettra une copie certifiée conforme à chacun des États-Parties.",
                    style: false
                  }
                ]
              },
              {
                name: "Article second",
                paragraphs: [
                  {
                    value: "Le présent Traité entrera en vigueur soixante (60) Jours après la date du dépôt du huitième instrument de ratification.",
                    style: false
                  },
                  {
                    value:
                      "Les instruments de ratification et les instruments d’adhésion seront déposés auprès du Gouvernement du Sénégal qui sera le Gouvernement dépositaire. Copie en sera délivrée au Secrétariat Permanent par ce dernier.",
                    style: false
                  },
                  {
                    value:
                      "Le Gouvernement dépositaire enregistrera le présent Traité auprès de l’Union Africaine et auprès de l’Organisation des Nations Unies conformément à l’article 102 de la Charte des Nations Unies.",
                    style: false
                  },
                  {
                    value: "Une copie du présent Traité enregistrée sera délivrée au Secrétariat Permanent par le Gouvernement dépositaire.",
                    style: false
                  },
                  {
                    value: "Le Conseil des Ministres approuvera la version consolidée du Traité révisé.",
                    style: false
                  },
                  {
                    value: "En foi de quoi, les Chefs d’État et de Gouvernement et plénipotentiaires, soussignés, ont apposé leur signature au bas du présent Traité.",
                    style: false
                  },
                  {
                    value: "Fait à Québec, le 17 Octobre 2008",
                    style: false
                  },
                  {
                    value: "Le Président de la République du BÉNIN,",
                    style: false
                  },
                  {
                    value: "Boni YAYI",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président du BURKINA FASO,",
                    style: false
                  },
                  {
                    value: "Blaise COMPAORE",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République du CAMEROUN,",
                    style: false
                  },
                  {
                    value: "Paul BIYA",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République CENTRAFRICAINE",
                    style: false
                  },
                  {
                    value: "François BOZIZE",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de l’Union des COMORES,",
                    style: false
                  },
                  {
                    value: "Ahmed Abdallàh Mohamed SAMBI",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République du CONGO,",
                    style: false
                  },
                  {
                    value: "Denis SASSOU N’GUESSO",
                    style: "font-bold"
                  },
                  {
                    value: "Pour le Président de la République de CÔTE D’IVOIRE,",
                    style: false
                  },
                  {
                    value: "Youssouf BAKAYOKO,",
                    style: "font-bold"
                  },
                  {
                    value: "Ministre des Affaires Etrangères",
                    style: false
                  },
                  {
                    value: "Le Président de la République GABONAISE,",
                    style: false
                  },
                  {
                    value: "El Hadj OMAR BONGO ONDIMBA",
                    style: "font-bold"
                  },
                  {
                    value: "Pour le Président de la République de GUINÉE,",
                    style: false
                  },
                  {
                    value: "Ahmed Tidiane SOUARE,",
                    style: "font-bold"
                  },
                  {
                    value: "Premier Ministre",
                    style: false
                  },
                  {
                    value: "Pour le Président de la République de GUINÉE-BISSAU,",
                    style: false
                  },
                  {
                    value: "Maria da Conceiçâo NOBRE CABRAL,",
                    style: "font-bold"
                  },
                  {
                    value: "Ministre des Affaires Etrangères",
                    style: false
                  },
                  {
                    value: "Le Président de la République de GUINÉE ÉQUATORIALE,",
                    style: false
                  },
                  {
                    value: "Teodoro OBIANG NGUEMA MBASOGO",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République du MALI,",
                    style: false
                  },
                  {
                    value: "Amadou Toumani TOURE",
                    style: "font-bold"
                  },
                  {
                    value: "Pour le Président de la République du NIGER,",
                    style: false
                  },
                  {
                    value: "Seyni OUMAROU",
                    style: "font-bold"
                  },
                  {
                    value: "Premier Ministre",
                    style: false
                  },
                  {
                    value: "Le Président de la République du SÉNÉGAL,",
                    style: false
                  },
                  {
                    value: "Abdoulaye WADE",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République du TCHAD,",
                    style: false
                  },
                  {
                    value: "Idriss DEBY ITNO",
                    style: "font-bold"
                  },
                  {
                    value: "Pour le Président de la République TOGOLAISE,",
                    style: false
                  },
                  {
                    value: "Gilbert FOSSOUN HOUNGBO",
                    style: "font-bold"
                  },
                  {
                    value: "Premier Ministre",
                    style: false
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        name: "TRAITÉ RELATIF À L’HARMONISATION DU DROIT DES AFFAIRES EN AFRIQUE, SIGNÉ A PORT-LOUIS LE 17 OCTOBRE 1993, TEL QUE RÉVISÉ À QUÉBEC LE 17 OCTOBRE 2008\u200c",
        info: "(Journal Officiel N°25 du 1er décembre 2011)",
        headerTwoList: [
          {
            name: "PRÉAMBULE",
            headerThreeList: [
              {
                name: "",
                paragraphs: [
                  {
                    value: "Le Président de la République du BÉNIN,",
                    style: false
                  },
                  {
                    value: "Le Président du BURKINA FASO,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du CAMEROUN,",
                    style: false
                  },
                  {
                    value: "Le Président de la République CENTRAFRICAINE,",
                    style: false
                  },
                  {
                    value: "Le Président de la République Fédérale Islamique des COMORES,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du CONGO,",
                    style: false
                  },
                  {
                    value: "Le Président de la République de CÔTE D’IVOIRE,",
                    style: false
                  },
                  {
                    value: "Le Président de la République GABONAISE,",
                    style: false
                  },
                  {
                    value: "Le Président de la République de GUINÉE,",
                    style: false
                  },
                  {
                    value: "Le Président de la République de GUINÉE BISSAU,",
                    style: false
                  },
                  {
                    value: "Le Président de la République de GUINÉE ÉQUATORIALE,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du MALI,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du NIGER,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du SÉNÉGAL,",
                    style: false
                  },
                  {
                    value: "Le Président de la République du TCHAD,",
                    style: false
                  },
                  {
                    value: "Le Président de la République TOGOLAISE,",
                    style: false
                  },
                  {
                    value: "Hautes parties contractantes au Traité relatif à l’harmonisation du droit des affaires en Afrique,",
                    style: false
                  },
                  {
                    value:
                      "Déterminés à accomplir de nouveaux progrès sur la voie de l’unité africaine et à établir un courant de confiance en faveur des économies de leurs pays en vue de créer un nouveau pôle de développement en Afrique ;",
                    style: false
                  },
                  {
                    value: "Réaffirmant leur engagement en faveur de l’institution d’une communauté économique africaine ;",
                    style: false
                  },
                  {
                    value:
                      "Convaincus que l’appartenance à la zone franc, facteur de stabilité économique et monétaire, constitue un atout majeur pour la réalisation progressive de leur intégration économique et que cette intégration doit également être poursuivie dans un cadre africain plus large ;",
                    style: false
                  },
                  {
                    value:
                      "Persuadés que la réalisation de ces objectifs suppose la mise en place dans leurs États d’un Droit des Affaires harmonisé, simple, moderne et adapté, afin de faciliter l’activité des entreprises ;",
                    style: false
                  },
                  {
                    value:
                      "Conscients qu’il est essentiel que ce droit soit appliqué avec diligence, dans les conditions propres à garantir la sécurité juridique des activités économiques afin, de favoriser l’essor de celles-ci et d’encourager l’investissement ;",
                    style: false
                  },
                  {
                    value: "Désireux de promouvoir l’arbitrage comme instrument de règlement des différends contractuels ;",
                    style: false
                  },
                  {
                    value: "Décidés à accomplir en commun de nouveaux efforts en vue d’améliorer la formation des magistrats et des auxiliaires de justice ;",
                    style: false
                  },
                  {
                    value: "Conviennent de ce qui suit :",
                    style: "font-bold"
                  }
                ]
              }
            ]
          },
          {
            name: "Titre I : Dispositions générales",
            headerThreeList: [
              {
                name: "Article premier",
                paragraphs: [
                  {
                    value:
                      "Le présent Traité a pour objet l’harmonisation du droit des affaires dans les États-Parties par l’élaboration et l’adoption de règles communes simples, modernes et adaptées à la situation de leurs économies, par la mise en œuvre de procédures judiciaires appropriées et par l’encouragement au recours à l’arbitrage pour le règlement des différends contractuels.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 2",
                paragraphs: [
                  {
                    value:
                      "Pour l’application du présent Traité, entrent dans le domaine du droit des affaires l’ensemble des règles relatives au droit des sociétés et au statut juridique des commerçants, au recouvrement des créances, aux sûretés et aux voies d’exécution, au régime du redressement des entreprises et de la liquidation judiciaire, au droit de l’arbitrage, au droit du travail, au droit comptable, au droit de la vente et des transports et, toute autre matière que le Conseil des Ministres déciderait, à l’unanimité, d’y inclure, conformément à l’objet du présent Traité et aux dispositions de l’article 8 ci-après.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 3 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value:
                      "La réalisation des tâches prévues au présent Traité est assurée par une organisation dénommée Organisation pour l’Harmonisation en Afrique du Droit des Affaires (OHADA).",
                    style: false
                  },
                  {
                    value:
                      "L’OHADA comprend la Conférence des Chefs d’État et de Gouvernement, le Conseil des Ministres, la Cour Commune de Justice et d’Arbitrage et le Secrétariat Permanent.",
                    style: false
                  },
                  {
                    value:
                      "Le siège de l’OHADA est fixé à Yaoundé en République du Cameroun. Il peut être transféré en tout autre lieu sur décision de la Conférence des Chefs d’État et de Gouvernement.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 4 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value:
                      "Des règlements pour l’application du présent Traité et des décisions seront pris chaque fois que de besoin, par le Conseil des Ministres, à la majorité absolue.",
                    style: false
                  }
                ]
              }
            ]
          },
          {
            name: "Titre II : Les Actes Uniformes",
            headerThreeList: [
              {
                name: "Article 5",
                paragraphs: [
                  {
                    value: "Les actes pris pour l’adoption des règles communes prévues à l’article premier du présent Traité sont qualifiés « Actes Uniformes ».",
                    style: false
                  },
                  {
                    value:
                      "Les Actes Uniformes peuvent inclure des dispositions d’incrimination pénale. Les États- Parties s’engagent à déterminer les sanctions pénales encourues.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 6",
                paragraphs: [
                  {
                    value:
                      "Les Actes Uniformes sont préparés par le Secrétariat Permanent en concertation avec les gouvernements des États-Parties. Ils sont délibérés et adoptés par le Conseil des Ministres après avis de la Cour Commune de Justice et d’Arbitrage.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 7 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value:
                      "Les projets d’Actes Uniformes sont communiqués par le Secrétariat Permanent aux Gouvernements des États-Parties, qui disposent d’un délai de quatre-vingt-dix jours à compter de la date de la réception de cette communication pour faire parvenir au Secrétariat Permanent leurs observations écrites.",
                    style: false
                  },
                  {
                    value:
                      "Toutefois, le délai prévu à l’alinéa premier peut être prorogé d’une durée équivalente en fonction des circonstances et de la nature du texte à adopter, à la diligence du Secrétariat Permanent.",
                    style: false
                  },
                  {
                    value:
                      "A l’expiration de ce délai, le projet d’Acte Uniforme, accompagné des observations des États-Parties et d’un rapport du Secrétariat Permanent, est immédiatement transmis pour avis par ce dernier à la Cour Commune de Justice et d’Arbitrage. La Cour donne son avis dans un délai de soixante jours à compter de la date de la réception de la demande de consultation.",
                    style: false
                  },
                  {
                    value:
                      "A l’expiration de ce nouveau délai, le Secrétariat Permanent met au point le texte définitif du projet d’Acte Uniforme dont il propose l’inscription à l’ordre du jour du prochain Conseil des Ministres.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 8",
                paragraphs: [
                  {
                    value: "L’adoption des Actes Uniformes par le Conseil des Ministres requiert l’unanimité des représentants des États-Parties présents et votants.",
                    style: false
                  },
                  {
                    value: "L’adoption des Actes Uniformes n’est valable que si les deux tiers au moins des États-Parties sont représentés.",
                    style: false
                  },
                  {
                    value: "L’abstention ne fait pas obstacle à l’adoption des Actes Uniformes.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 9 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value:
                      "Les Actes Uniformes sont publiés au Journal Officiel de l’OHADA par le Secrétariat Permanent dans les soixante jours suivant leur adoption. Ils sont applicables quatre-vingt-dix jours après cette publication, sauf modalités particulières d’entrée en vigueur prévues par les Actes Uniformes. Ils sont également publiés dans les États-Parties, au Journal Officiel ou par tout autre moyen approprié. Cette formalité n’a aucune incidence sur l’entrée en vigueur des Actes Uniformes.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 10",
                paragraphs: [
                  {
                    value:
                      "Les Actes Uniformes sont directement applicables et obligatoires dans les États-Parties, nonobstant toute disposition contraire de droit interne, antérieure ou postérieure.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 11",
                paragraphs: [
                  {
                    value: "Le Conseil des Ministres approuve sur proposition du Secrétaire Permanent le programme annuel d’harmonisation du droit des affaires.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 12 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value: "Les Actes Uniformes peuvent être modifiés, à la demande de tout État-Partie ou du Secrétariat Permanent, après autorisation du Conseil des Ministres.",
                    style: false
                  },
                  {
                    value: "La modification intervient dans les conditions prévues par les articles 6 à 9 ci-dessus.",
                    style: false
                  }
                ]
              }
            ]
          },
          {
            name: "Titre III : Le contentieux relatif à l’interprétation et à l’application des Actes Uniformes",
            headerThreeList: [
              {
                name: "Article 13",
                paragraphs: [
                  {
                    value: "Le contentieux relatif à l’application des Actes Uniformes est réglé en première instance et en appel par les juridictions des États-Parties.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 14 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value:
                      "La Cour Commune de Justice et d’Arbitrage assure l’interprétation et l’application communes du Traité ainsi que des règlements pris pour son application, des Actes Uniformes et des décisions.",
                    style: false
                  },
                  {
                    value:
                      "La Cour peut être consultée par tout État-Partie ou par le Conseil des Ministres sur toute question entrant dans le champ de l’alinéa précédent. La même faculté de solliciter l’avis consultatif de la Cour est reconnue aux juridictions nationales saisies en application de l’article 13 ci-dessus.",
                    style: false
                  },
                  {
                    value:
                      "Saisie par la voie du recours en cassation, la Cour se prononce sur les décisions rendues par les juridictions d’Appel des États-Parties dans toutes les affaires soulevant des questions relatives à l’application des Actes Uniformes et des règlements prévus au présent Traité à l’exception des décisions appliquant des sanctions pénales.",
                    style: false
                  },
                  {
                    value:
                      "Elle se prononce dans les mêmes conditions sur les décisions non susceptibles d’appel rendues par toute juridiction des États-Parties dans les mêmes contentieux.",
                    style: false
                  },
                  {
                    value: "En cas de cassation, elle évoque et statue sur le fond.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 15",
                paragraphs: [
                  {
                    value:
                      "Les pourvois en cassation prévus à l’article 14 ci-dessus sont portés devant la Cour Commune de Justice et d’Arbitrage, soit directement par l’une des parties à l’instance, soit sur renvoi d’une juridiction nationale statuant en cassation saisie d’une affaire soulevant des questions relatives à l’application des Actes Uniformes.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 16",
                paragraphs: [
                  {
                    value:
                      "La saisine de la Cour Commune de Justice et d’Arbitrage suspend toute procédure de cassation engagée devant une juridiction nationale contre la décision attaquée. Toutefois cette règle n’affecte pas les procédures d’exécution. Une telle procédure ne peut reprendre qu’après arrêt de la Cour Commune de Justice et d’Arbitrage se déclarant incompétente pour connaître de l’affaire.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 17 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value: "L’incompétence manifeste de la Cour Commune de Justice et d’Arbitrage peut être soulevée d’office ou par toute partie au litige in limine litis.",
                    style: false
                  },
                  {
                    value:
                      "La Cour se prononce dans les trente jours qui suivent la date de réception des observations de la partie adverse ou celle d’expiration du délai imparti pour la présentation desdites observations.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 18",
                paragraphs: [
                  {
                    value:
                      "Toute partie qui, après avoir soulevé l’incompétence d’une juridiction nationale statuant en cassation estime que cette juridiction a, dans un litige la concernant, méconnu la compétence de la Cour Commune de Justice et d’Arbitrage peut saisir cette dernière dans un délai de deux mois à compter de la notification de la décision contestée.",
                    style: false
                  },
                  {
                    value: "La Cour se prononce sur sa compétence par un arrêt qu’elle notifie tant aux parties qu’à la juridiction en cause.",
                    style: false
                  },
                  {
                    value: "Si la Cour décide que cette juridiction s’est déclarée compétente à tort, la décision rendue par cette juridiction est réputée nulle et non avenue.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 19",
                paragraphs: [
                  {
                    value:
                      "La procédure devant la Cour Commune de Justice et d’Arbitrage est fixée par un règlement adopté par le Conseil des Ministres dans les conditions prévues à l’article 8 ci-dessus publié au Journal Officiel de l’OHADA. Il est également publié au Journal Officiel des États-Parties ou par tout autre moyen approprié.",
                    style: false
                  },
                  {
                    value: "Cette procédure est contradictoire. Le ministère d’un avocat est obligatoire. L’audience est publique.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 20",
                paragraphs: [
                  {
                    value:
                      "Les arrêts de la Cour Commune de Justice et d’Arbitrage ont l’autorité de la chose jugée et la force exécutoire. Ils reçoivent sur le territoire de chacun des États-Parties une exécution forcée dans les mêmes conditions que les décisions des juridictions nationales. Dans une même affaire, aucune décision contraire à un arrêt de la Cour Commune de Justice et d’Arbitrage ne peut faire l’objet d’une exécution forcée sur le territoire d’un État-Partie.",
                    style: false
                  }
                ]
              }
            ]
          },
          {
            name: "Titre IV : L’arbitrage",
            headerThreeList: [
              {
                name: "Article 21",
                paragraphs: [
                  {
                    value:
                      "En application d’une clause compromissoire ou d’un compromis d’arbitrage, toute partie à un contrat, soit que l’une des parties ait son domicile ou sa résidence habituelle dans un des États-Parties, soit que le contrat soit exécuté ou à exécuter en tout ou partie sur le territoire d’un ou plusieurs États-Parties, peut soumettre un différend d’ordre contractuel à la procédure d’arbitrage prévue par le présent titre.",
                    style: false
                  },
                  {
                    value:
                      "La Cour Commune de Justice et d’Arbitrage ne tranche pas elle-même les différends. Elle nomme ou confirme les arbitres, est informée du déroulement de l’instance, et examine les projets de sentences, conformément à l’article 24 ci-après.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 22",
                paragraphs: [
                  {
                    value:
                      "Le différend peut être tranché par un arbitre unique ou par trois arbitres. Dans les articles suivants, l’expression « l’arbitre » vise indifféremment le ou les arbitres.",
                    style: false
                  },
                  {
                    value:
                      "Lorsque les parties sont convenues que le différend sera tranché par un arbitre unique, elles peuvent le désigner d’un commun accord pour confirmation par la Cour. Faute d’entente entre les parties dans un délai de trente jours à partir de la notification de la demande d’arbitrage à l’autre partie, l’arbitre sera nommé par la Cour.",
                    style: false
                  },
                  {
                    value:
                      "Lorsque trois arbitres ont été prévus, chacune des parties - dans la demande d’arbitrage ou dans la réponse à celle-ci - désigne un arbitre indépendant pour confirmation par la Cour. Si l’une des parties s’abstient, la nomination est faite par la Cour. Le troisième arbitre qui assume la présidence du tribunal arbitral est nommé par la Cour, à moins que les parties n’aient prévu que les arbitres qu’elles ont désignés devraient faire choix du troisième arbitre dans un délai déterminé. Dans ce dernier cas, il appartient à la Cour de confirmer le troisième arbitre. Si, à l’expiration du délai fixé par les parties ou imparti par la Cour, les arbitres désignés par les parties n’ont pu se mettre d’accord, le troisième arbitre est nommé par la Cour.",
                    style: false
                  },
                  {
                    value:
                      "Si les parties n’ont pas fixé d’un commun accord le nombre des arbitres, la Cour nomme un arbitre unique, à moins que le différend ne lui paraisse justifier la désignation de trois arbitres. Dans ce dernier cas, les parties disposeront d’un délai de quinze jours pour procéder à la désignation des arbitres.",
                    style: false
                  },
                  {
                    value:
                      "Les arbitres peuvent être choisis sur la liste des arbitres établie par la Cour et mise à jour annuellement. Les membres de la Cour ne peuvent pas être inscrits sur cette liste.",
                    style: false
                  },
                  {
                    value: "En cas de récusation d’un arbitre par une partie, la Cour statue. Sa décision n’est pas susceptible de recours.",
                    style: false
                  },
                  {
                    value:
                      "Il y a lieu à remplacement d’un arbitre lorsqu’il est décédé ou empêché, lorsqu’il doit se démettre de ses fonctions à la suite d’une récusation ou pour tout autre motif ou, lorsque la Cour, après avoir recueilli ses observations, constate qu’il ne remplit pas ses fonctions conformément aux stipulations du présent titre ou du règlement d’arbitrage ou, dans les délais impartis. Dans chacun de ces cas, il est procédé conformément aux deuxième et troisième alinéas.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 23",
                paragraphs: [
                  {
                    value:
                      "Tout tribunal d’un État-Partie saisi d’un litige que les parties étaient convenues de soumettre à l’arbitrage se déclarera incompétent si l’une des parties le demande et, renverra, le cas échéant, à la procédure d’arbitrage prévue au présent Traité.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 24",
                paragraphs: [
                  {
                    value: "Avant de signer une sentence partielle ou définitive, l’arbitre doit en soumettre le projet à la Cour Commune de Justice et d’Arbitrage.",
                    style: false
                  },
                  {
                    value: "Celle-ci ne peut proposer que des modifications de pure forme.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 25",
                paragraphs: [
                  {
                    value:
                      "Les sentences arbitrales rendues conformément aux stipulations du présent titre ont l’autorité définitive de la chose jugée sur le territoire de chaque État-Partie au même titre que les décisions rendues par les juridictions de l’État.",
                    style: false
                  },
                  {
                    value: "Elles peuvent faire l’objet d’une exécution forcée en vertu d’une décision d’exequatur.",
                    style: false
                  },
                  {
                    value:
                      "La Cour Commune de Justice et d’Arbitrage a seule compétence pour rendre une telle décision. L’exequatur ne peut être refusé que dans les cas suivants :",
                    style: false
                  },
                  {
                    value: "1. si l’arbitre a statué sans convention d’arbitrage ou sur une convention nulle ou expirée ;",
                    style: "ml-8"
                  },
                  {
                    value: "2. si l’arbitre a statué sans se conformer à la mission qui lui avait été conférée ;",
                    style: "ml-8"
                  },
                  {
                    value: "3. lorsque le principe de la procédure contradictoire n’a pas été respecté ;",
                    style: "ml-8"
                  },
                  {
                    value: "4. si la sentence est contraire à l’ordre public international.",
                    style: "ml-8"
                  }
                ]
              },
              {
                name: "Article 26",
                paragraphs: [
                  {
                    value:
                      "Le Règlement d’arbitrage de la Cour Commune de Justice et d’Arbitrage est fixé par le Conseil des Ministres dans les conditions prévues à l’article 8 ci-dessus. Il est publié au Journal Officiel de l’OHADA. Il est également publié au Journal Officiel des États-Parties ou par tout autre moyen approprié.",
                    style: false
                  }
                ]
              }
            ]
          },
          {
            name: "Titre V : Les institutions",
            headerThreeList: [
              {
                name: "Article 27 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value:
                      "1. La Conférence des Chefs d’État et de Gouvernement est composée des Chefs d’État et de Gouvernement des États-Parties. Elle est présidée par le Chef d’État ou de Gouvernement dont le pays assure la présidence du Conseil des Ministres.",
                    style: "ml-8"
                  },
                  {
                    value: "2. Le Conseil des Ministres est composé des Ministres chargés de la Justice et des Finances des États-Parties.",
                    style: "ml-8"
                  },
                  {
                    value: "La présidence du Conseil des Ministres est exercée à tour de rôle et par ordre alphabétique, pour une durée d’un an, par chaque État-Partie.",
                    style: false
                  },
                  {
                    value: "Le Président du Conseil des Ministres est assisté par le Secrétaire Permanent.",
                    style: false
                  },
                  {
                    value:
                      "Les États adhérents assurent pour la première fois la présidence du Conseil des Ministres dans l’ordre de leur adhésion, après le tour des pays signataires du Traité.",
                    style: false
                  },
                  {
                    value:
                      "Si un État-Partie ne peut exercer la présidence du Conseil des Ministres pendant l’année où elle lui revient, le Conseil désigne, pour exercer cette présidence, l’État venant immédiatement après, dans l’ordre prévu aux alinéas précédents.",
                    style: false
                  },
                  {
                    value:
                      "Toutefois, l’État précédemment empêché qui estime être en mesure d’assurer la présidence en saisit, en temps utile, le Secrétaire Permanent, pour décision à prendre par le Conseil des Ministres.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 28",
                paragraphs: [
                  {
                    value:
                      "Le Conseil des Ministres se réunit au moins une fois par an sur convocation de son Président à l’initiative de celui-ci ou, du tiers des États-Parties. Il ne peut valablement délibérer que si les deux tiers au moins des États-Parties sont représentés.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 29",
                paragraphs: [
                  {
                    value: "Le Président du Conseil des Ministres arrête l’ordre du jour du Conseil sur la proposition du Secrétaire Permanent.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 30",
                paragraphs: [
                  {
                    value:
                      "Les décisions du Conseil des Ministres autres que celles prévues à l’article 8 ci-dessus sont prises à la majorité absolue des États-Parties présents et votants. Chacun des États dispose d’une voix.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 31 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value: "La Cour Commune de Justice et d’Arbitrage est composée de neuf juges.",
                    style: false
                  },
                  {
                    value:
                      "Toutefois, Le Conseil des Ministres peut, compte tenu des nécessités de service et des possibilités financières, fixer un nombre de juges supérieur à celui prévu à l’alinéa précédent.",
                    style: false
                  },
                  {
                    value:
                      "Les Juges de la Cour Commune de Justice et d’Arbitrage sont élus pour un mandat de sept ans non renouvelable, parmi les ressortissants des États-Parties. Ils sont choisis parmi :",
                    style: false
                  },
                  {
                    value:
                      "1. les magistrats ayant acquis une expérience professionnelle d’au moins quinze années et réunissant les conditions requises pour l’exercice dans leurs pays respectifs de hautes fonctions judiciaires ;",
                    style: "ml-8"
                  },
                  {
                    value: "2. les avocats inscrits au barreau de l’un des États-Parties, ayant au moins quinze années d’expérience professionnelle ;",
                    style: "ml-8"
                  },
                  {
                    value: "3. les professeurs de droit ayant au moins quinze années d’expérience professionnelle.",
                    style: "ml-8"
                  },
                  {
                    value: "Un tiers des membres de la Cour doit appartenir aux catégories visées aux points 2 et 3 de l’alinéa précédent.",
                    style: false
                  },
                  {
                    value: "La Cour ne peut comprendre plus d’un ressortissant du même État.",
                    style: false
                  },
                  {
                    value: "Les modalités d’application du présent article seront précisées par le règlement prévu à l’article 19 ci-dessus.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 32",
                paragraphs: [
                  {
                    value:
                      "Les membres de la Cour sont élus au scrutin secret par le Conseil des Ministres sur une liste de personnes présentées à cet effet par les États-Parties.",
                    style: false
                  },
                  {
                    value: "Chaque État-Partie peut présenter deux candidats au plus.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 33",
                paragraphs: [
                  {
                    value:
                      "Le Secrétaire Permanent invite les États-Parties à procéder, dans un délai d’au moins quatre mois, avant les élections, à la présentation des candidats à la Cour.",
                    style: false
                  },
                  {
                    value:
                      "Le Secrétaire Permanent dresse la liste alphabétique des personnes ainsi présentées et la communique un mois au moins avant les élections aux États-Parties.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 34",
                paragraphs: [
                  {
                    value: "Après leur élection, les membres de la Cour font la déclaration solennelle de bien et fidèlement remplir leurs fonctions en toute impartialité.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 35",
                paragraphs: [
                  {
                    value:
                      "En cas de décès d’un membre de la Cour, le Président de la Cour en informe immédiatement le Secrétaire Permanent, qui déclare le siège vacant à partir de la date du décès.",
                    style: false
                  },
                  {
                    value:
                      "En cas de démission d’un membre de la Cour ou si, de l’avis unanime des autres membres de la Cour, un membre a cessé de remplir ses fonctions pour toute autre cause qu’une absence de caractère temporaire ou, n’est plus en mesure de les remplir, le Président de la Cour, après avoir invité l’intéressé à présenter à la Cour ses observations orales, en informe le Secrétaire Permanent qui déclare alors le siège vacant.",
                    style: false
                  },
                  {
                    value:
                      "Dans chacun des cas prévus ci-dessus, Le Conseil des Ministres procède, dans les conditions prévues aux articles 32 et 33 ci-dessus, au remplacement du membre dont le siège est devenu vacant, pour la fraction du mandat restant à courir, sauf si cette fraction est inférieure à six mois.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 36",
                paragraphs: [
                  {
                    value: "Les membres de la Cour sont inamovibles.",
                    style: false
                  },
                  {
                    value: "Tout membre de la Cour conserve son mandat jusqu’à la date d’entrée en fonction de son successeur.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 37",
                paragraphs: [
                  {
                    value:
                      "La Cour élit en son sein, pour une durée de trois ans et demi non renouvelable, son Président et ses deux Vice-présidents. Les membres de la Cour dont le mandat restant à courir à la date de l’élection est inférieur à cette durée peuvent être élus pour exercer ces fonctions jusqu’à l’expiration dudit mandat. Ils peuvent être renouvelés dans ces fonctions s’ils sont élus par le Conseil des Ministres pour exercer un nouveau mandat de membre de la Cour. Aucun membre de la Cour ne peut exercer des fonctions politiques ou administratives. L’exercice de toute activité rémunérée doit être autorisé par la Cour.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 38",
                paragraphs: [
                  {
                    value:
                      "La durée du mandat des sept juges nommés simultanément pour la constitution initiale de la Cour sera respectivement de trois ans, quatre ans, cinq ans, six ans, sept ans, huit ans et neuf ans. Elle sera déterminée pour chacun d’eux par tirage au sort effectué en Conseil des Ministres par le Président du Conseil. Le premier renouvellement de la Cour aura lieu trois ans après la constitution initiale de celle-ci.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 39 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value:
                      "Le Président de la Cour Commune de Justice et d’Arbitrage nomme le Greffier en chef de la Cour après avis de celle-ci, parmi les greffiers en chef ayant exercé leurs fonctions pendant au moins quinze ans et présentés par les États-Parties.",
                    style: false
                  },
                  {
                    value:
                      "Après avis de la Cour, le Président nomme également le Secrétaire général chargé d’assister celle-ci dans l’exercice de ses attributions d’administration de l’arbitrage, selon les critères définis par un règlement du Conseil des Ministres.",
                    style: false
                  },
                  {
                    value: "Il pourvoit, sur proposition, selon les cas, du Greffier en chef ou du Secrétaire général, aux autres emplois.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 40 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value:
                      "Le Secrétariat Permanent est l’organe exécutif de l’OHADA. Il est dirigé par un Secrétaire Permanent nommé par le Conseil des Ministres pour un mandat de quatre ans renouvelable une fois.",
                    style: false
                  },
                  {
                    value: "Le Secrétaire Permanent représente l’OHADA. Il assiste le Conseil des Ministres.",
                    style: false
                  },
                  {
                    value:
                      "La nomination et les attributions du Secrétaire Permanent ainsi que l’organisation et le fonctionnement du Secrétariat Permanent sont définis par un règlement du Conseil des Ministres.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 41 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value:
                      "Il est institué un établissement de formation, de perfectionnement et de recherche en Droit des Affaires dénommé École Régionale Supérieure de la Magistrature (ERSUMA).",
                    style: false
                  },
                  {
                    value: "L’établissement est rattaché au Secrétariat Permanent.",
                    style: false
                  },
                  {
                    value: "La dénomination et l’orientation de l’établissement peuvent être changées par un règlement du Conseil des Ministres.",
                    style: false
                  },
                  {
                    value: "L’établissement est dirigé par un Directeur Général nommé en Conseil des Ministres pour un mandat de quatre ans renouvelable une fois.",
                    style: false
                  },
                  {
                    value: "L’organisation, le fonctionnement, les ressources et les prestations de l’établissement sont définis par un règlement du Conseil des Ministres.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 42 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value: "Les langues de travail de l’OHADA sont : le français, l’anglais, l’espagnol et le portugais.",
                    style: false
                  },
                  {
                    value:
                      "Avant traduction dans les autres langues, les documents déjà publiés en français produisent tous leurs effets. En cas de divergence entre les différentes traductions, la version française fait foi.",
                    style: false
                  }
                ]
              }
            ]
          },
          {
            name: "Titre VI : Dispositions financières",
            headerThreeList: [
              {
                name: "Article 43 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value: "Les ressources de l’OHADA sont composées notamment :",
                    style: false
                  },
                  {
                    value: "1. des contributions annuelles des États-Parties dont les modalités sont définies par un règlement du Conseil des Ministres ;",
                    style: "ml-8"
                  },
                  {
                    value: "2. des concours prévus par les conventions conclues par l’OHADA avec des États ou des organisations internationales ;",
                    style: "ml-8"
                  },
                  {
                    value: "3. de dons et legs.",
                    style: "ml-8"
                  },
                  {
                    value: "Les contributions annuelles des États-Parties sont arrêtées par le Conseil des Ministres.",
                    style: false
                  },
                  {
                    value: "Le Conseil des Ministres approuve les conventions prévues au paragraphe b et accepte les dons et legs prévus au paragraphe c.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 44",
                paragraphs: [
                  {
                    value:
                      "Le barème des tarifs de la procédure d’arbitrage instituée par le présent Traité ainsi que la répartition des recettes correspondantes sont approuvés par le Conseil des Ministres.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 45 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value: "Le budget annuel de l’OHADA est adopté par le Conseil des Ministres.",
                    style: false
                  },
                  {
                    value:
                      "Les comptes de l’exercice clos sont certifiés par des commissaires aux comptes désignés par le Conseil des Ministres. Ils sont approuvés par le Conseil des Ministres.",
                    style: false
                  }
                ]
              }
            ]
          },
          {
            name: "Titre VII : Statut, immunités et privilèges",
            headerThreeList: [
              {
                name: "Article 46",
                paragraphs: [
                  {
                    value: "L’OHADA a la pleine personnalité juridique internationale. Elle a en particulier la capacité :",
                    style: false
                  },
                  {
                    value: "1. de contracter ;",
                    style: "ml-8"
                  },
                  {
                    value: "2. d’acquérir des biens meubles et immeubles et d’en disposer ;",
                    style: "ml-8"
                  },
                  {
                    value: "3. d’ester en justice.",
                    style: "ml-8"
                  }
                ]
              },
              {
                name: "Article 47",
                paragraphs: [
                  {
                    value: "Afin de pouvoir remplir ses fonctions, l’OHADA jouit sur le territoire de chaque État-Partie des immunités et privilèges prévus au présent titre.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 48",
                paragraphs: [
                  {
                    value: "L’OHADA, ses biens et ses avoirs ne peuvent faire l’objet d’aucune action judiciaire, sauf si elle renonce à cette immunité.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 49 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value:
                      "Dans les conditions déterminées par un règlement, les fonctionnaires et employés de l’OHADA, les juges de la Cour Commune de Justice et d’Arbitrage ainsi que les arbitres nommés ou confirmés par cette dernière jouissent dans l’exercice de leurs fonctions des privilèges et immunités diplomatiques.",
                    style: false
                  },
                  {
                    value: "Les immunités et privilèges mentionnés ci-dessus peuvent être, selon les circonstances, levés par le Conseil des Ministres.",
                    style: false
                  },
                  {
                    value: "En outre, les juges ne peuvent être poursuivis pour des actes accomplis en dehors de l’exercice de leurs fonctions qu’avec l’autorisation de la Cour.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 50",
                paragraphs: [
                  {
                    value: "Les archives de l’OHADA sont inviolables où qu’elles se trouvent.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 51",
                paragraphs: [
                  {
                    value:
                      "L’OHADA, ses avoirs, ses biens et ses revenus ainsi que les opérations autorisées par le présent Traité sont exonérés de tous Impôts, taxes et droits de douane. L’OHADA est également exempte de toute obligation relative au recouvrement ou au paiement d’impôts, de taxes ou de droits de douane.",
                    style: false
                  }
                ]
              }
            ]
          },
          {
            name: "Titre VIII : Clauses protocolaires",
            headerThreeList: [
              {
                name: "Article 52",
                paragraphs: [
                  {
                    value: "Le présent Traité est soumis à la ratification des États signataires conformément à leurs procédures constitutionnelles.",
                    style: false
                  },
                  {
                    value:
                      "Le présent Traité entrera en vigueur soixante jours après la date du dépôt du septième instrument de ratification. Toutefois, si la date de dépôt du septième instrument de ratification est antérieure aux cent quatre-vingtième jour qui suit le jour de la signature du Traité, le Traité entrera en vigueur le deux cent quarantième jour suivant la date de sa signature.",
                    style: false
                  },
                  {
                    value:
                      "A l’égard de tout État signataire déposant ultérieurement son instrument de ratification, le Traité et les Actes Uniformes adoptés avant la ratification entreront en vigueur soixante jours après la date dudit dépôt.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 53",
                paragraphs: [
                  {
                    value:
                      "Le présent Traité est, dès son entrée en vigueur, ouvert à l’adhésion de tout État membre de l’OUA et non signataire du Traité. Il est également ouvert à l’adhésion de tout autre État non membre de l’OUA invité à y adhérer du commun accord de tous les États-Parties.",
                    style: false
                  },
                  {
                    value:
                      "A l’égard de tout État adhérent, le présent Traité et les Actes Uniformes adoptés avant l’adhésion entreront en vigueur soixante jours après la date du dépôt de l’instrument d’adhésion.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 54",
                paragraphs: [
                  {
                    value: "Aucune réserve n’est admise au présent Traité.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 55",
                paragraphs: [
                  {
                    value:
                      "Dès l’entrée en vigueur du Traité, les institutions communes prévues aux articles 27 à 41 ci-dessus seront mises en place. Les États signataires du Traité ne l’ayant pas encore ratifié pourront en outre siéger au Conseil des Ministres en qualité d’observateurs sans droit de vote.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 56",
                paragraphs: [
                  {
                    value:
                      "Tout différend qui pourrait surgir entre les États-Parties quant à l’interprétation ou à l’application du présent Traité et qui ne serait pas résolu à l’amiable peut être porté par un État-Partie devant la Cour Commune de Justice et d’Arbitrage. Si la Cour compte sur le siège un juge de la nationalité d’une des parties, toute autre partie peut désigner un juge ad hoc pour siéger dans l’affaire. Ce dernier devra remplir les conditions fixées à l’article 31 ci-dessus.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 57 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value:
                      "Les instruments de ratification et les instruments d’adhésion seront déposés auprès du Gouvernement du Sénégal qui sera le Gouvernement dépositaire. Copie en sera délivrée au Secrétariat Permanent par ce dernier.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 58",
                paragraphs: [
                  {
                    value:
                      "Tout État ratifiant le présent Traité ou y adhérant postérieurement à l’entrée en vigueur d’un amendement au présent Traité devient par là-même partie au Traité tel qu’amendé.",
                    style: false
                  },
                  {
                    value:
                      "Le Conseil des Ministres ajoute le nom de l’État adhérent sur la liste prévue avant le nom de l’État qui assure la présidence du Conseil des Ministres à la date de l’adhésion.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 59 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value:
                      "Le Gouvernement dépositaire enregistrera le Traité auprès de l’Union Africaine et auprès de l’Organisation des Nations Unies conformément à l’article 102 de la Charte des Nations Unies.",
                    style: false
                  },
                  {
                    value: "Une copie du Traité enregistré sera délivrée au Secrétariat Permanent par le Gouvernement dépositaire.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 60",
                paragraphs: [
                  {
                    value: "Le gouvernement dépositaire avisera sans délai tous les États signataires ou adhérents",
                    style: false
                  },
                  {
                    value: "1. des dates de signature ;",
                    style: "ml-8"
                  },
                  {
                    value: "2. des dates d’enregistrement du Traité ;",
                    style: "ml-8"
                  },
                  {
                    value: "3. des dates de dépôt des instruments de ratification et d’adhésion ;",
                    style: "ml-8"
                  },
                  {
                    value: "4. de la date d’entrée en vigueur du Traité.",
                    style: "ml-8"
                  }
                ]
              }
            ]
          },
          {
            name: "Titre IX : Révision et Dénonciation",
            headerThreeList: [
              {
                name: "Article 61 (tel que révisé à Québec le 17 octobre 2008)",
                paragraphs: [
                  {
                    value:
                      "Le Traité peut être amendé ou révisé si un État-Partie envoie, à cet effet, une demande écrite au Secrétariat Permanent de l’OHADA qui en saisit le Conseil des Ministres.",
                    style: false
                  },
                  {
                    value: "Le Conseil des Ministres apprécie l’objet de la demande et l’étendue de la modification.",
                    style: false
                  },
                  {
                    value: "L’amendement ou la révision doit être adopté dans les mêmes formes que le Traité à la diligence du Conseil des Ministres.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 62",
                paragraphs: [
                  {
                    value:
                      "Le présent Traité a une durée illimitée. Il ne peut, en tout état de cause, être dénoncé avant dix années à partir de la date de son entrée en vigueur.",
                    style: false
                  },
                  {
                    value:
                      "Toute dénonciation du présent Traité doit être notifiée au gouvernement dépositaire et ne produira d’effet qu’une année après la date de cette notification.",
                    style: false
                  }
                ]
              },
              {
                name: "Article 63",
                paragraphs: [
                  {
                    value:
                      "Le présent Traité, rédigé en deux exemplaires, en langue française, sera déposé dans les archives du gouvernement de la République du Sénégal qui remettra une copie certifiée conforme à chacun des autres États-Parties signataires.",
                    style: false
                  },
                  {
                    value: "En foi de quoi les Chefs d’État et plénipotentiaires soussignés ont apposé leur signature au bas du présent Traité.",
                    style: false
                  },
                  {
                    value: "Pour le Traité relatif à l’harmonisation du droit des affaires en Afrique,",
                    style: "font-bold"
                  },
                  {
                    value: "Signé à Port Louis le 17 octobre 1993",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République du BÉNIN",
                    style: false
                  },
                  {
                    value: "Nicéphore SOGLO",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président du BURKINA FASO",
                    style: false
                  },
                  {
                    value: "Blaise COMPAORE",
                    style: "font-bold"
                  },
                  {
                    value: "Pour le Président de la République du CAMEROUN",
                    style: false
                  },
                  {
                    value: "Le Ministre des Relations Extérieures",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République CENTRAFRICAINE",
                    style: false
                  },
                  {
                    value: "Ange-Félix PATASSE",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République Fédérale Islamique des COMORES",
                    style: false
                  },
                  {
                    value: "SAID MOHAMED DJOUHAR",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République du CONGO",
                    style: false
                  },
                  {
                    value: "Pascal LISSOUBA",
                    style: "font-bold"
                  },
                  {
                    value: "Pour le Président de la République de CÔTE D’IVOIRE",
                    style: false
                  },
                  {
                    value: "Alassane Dramane OUATTARA",
                    style: "font-bold"
                  },
                  {
                    value: "Premier Ministre",
                    style: false
                  },
                  {
                    value: "Pour le Président de la République GABONAISE",
                    style: false
                  },
                  {
                    value: "Casimir Oyé MBA",
                    style: "font-bold"
                  },
                  {
                    value: "Premier Ministre",
                    style: false
                  },
                  {
                    value: "Le Président de la République de GUINÉE ÉQUATORIALE",
                    style: false
                  },
                  {
                    value: "Général Teodoro OBIANG NGUEMA MBASOGO",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République du MALI",
                    style: false
                  },
                  {
                    value: "Alpha Oumar KONARÉ",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République du NIGER",
                    style: false
                  },
                  {
                    value: "Mahamane OUSMANE",
                    style: "font-bold"
                  },
                  {
                    value: "Pour le Président de la République du SÉNÉGAL",
                    style: false
                  },
                  {
                    value: "Moustapha NIASSE",
                    style: "font-bold"
                  },
                  {
                    value: "Ministre d’État, des Affaires Étrangères et des Sénégalais de l’Extérieur",
                    style: false
                  },
                  {
                    value: "Le Président de la République du TCHAD",
                    style: false
                  },
                  {
                    value: "Colonel Idriss DEBY",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République TOGOLAISE",
                    style: false
                  },
                  {
                    value: "Gnassingbé EYADEMA",
                    style: "font-bold"
                  },
                  {
                    value:
                      "Pour le Traité portant révision du Traité relatif à l’harmonisation du droit des affaires en Afrique, signé à Port Louis le 17 octobre 1993, tel que signé à Québec le 17 octobre 2008,",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République du BÉNIN",
                    style: false
                  },
                  {
                    value: "Boni YAYI",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président du BURKINA FASO",
                    style: false
                  },
                  {
                    value: "Blaise COMPAORÉ",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République du CAMEROUN",
                    style: false
                  },
                  {
                    value: "Paul BIYA",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République CENTRAFRICAINE",
                    style: false
                  },
                  {
                    value: "François BOZIZÉ",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de l’Union des COMORES",
                    style: false
                  },
                  {
                    value: "Ahmed Abdallah Mohamed SAMBl",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République du CONGO",
                    style: false
                  },
                  {
                    value: "Denis SASSOU N’GUESSO",
                    style: "font-bold"
                  },
                  {
                    value: "Pour le Président de la République de CÔTE D’IVOIRE",
                    style: false
                  },
                  {
                    value: "Youssouf BAYAYOKO",
                    style: "font-bold"
                  },
                  {
                    value: "Ministre des Affaires Étrangères",
                    style: false
                  },
                  {
                    value: "Le Président de la République GABONAISE",
                    style: false
                  },
                  {
                    value: "El Hadj OMAR BONGO ONDIMBA",
                    style: "font-bold"
                  },
                  {
                    value: "Pour le Président de la République de GUINÉE",
                    style: false
                  },
                  {
                    value: "Ahmed Tidiane SOUARE",
                    style: "font-bold"
                  },
                  {
                    value: "Premier Ministre",
                    style: false
                  },
                  {
                    value: "Pour le Président de la République de GUINÉE-BISSAU",
                    style: false
                  },
                  {
                    value: "Maria da Conceiçao NOBRE GABRAL",
                    style: "font-bold"
                  },
                  {
                    value: "Ministre des Affaires Étrangères",
                    style: false
                  },
                  {
                    value: "Le Président de la République de GUINÉE ÉQUATORIALE",
                    style: false
                  },
                  {
                    value: "Theodoro OBIANG NGUEMA MBASOGO",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République du MALI",
                    style: false
                  },
                  {
                    value: "Amadou Toumani TOURE",
                    style: "font-bold"
                  },
                  {
                    value: "Pour le Président de la République du NIGER",
                    style: false
                  },
                  {
                    value: "Seyni OUMAROU",
                    style: "font-bold"
                  },
                  {
                    value: "Premier Ministre",
                    style: false
                  },
                  {
                    value: "Le Président de la République du SÉNÉGAL",
                    style: false
                  },
                  {
                    value: "Abdoulaye WADE",
                    style: "font-bold"
                  },
                  {
                    value: "Le Président de la République du TCHAD",
                    style: false
                  },
                  {
                    value: "Idriss DEBY ITNO",
                    style: "font-bold"
                  },
                  {
                    value: "Pour le Président de la République TOGOLAISE",
                    style: false
                  },
                  {
                    value: "Gilbert FOSSOUN HOUNGBO",
                    style: "font-bold"
                  },
                  {
                    value: "Premier Ministre",
                    style: false
                  },
                  {
                    value: "Consolidation approuvée par le Conseil des Ministre à Lomé, le 15 décembre 2010",
                    style: "font-bold"
                  },
                  {
                    value: "Pour le Conseil des Ministres, Le Président,",
                    style: false
                  },
                  {
                    value: "Biossey Kokou TOZOU",
                    style: "font-bold"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};

export default ohada;
