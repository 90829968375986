import React from "react";

const SearchBox = ({ value, help, error, onKeyHit, className, onKeyDown, onEnter, ...props }) => {
  const _handleKeyDown = e => {
    onKeyDown && onKeyDown();
    if (e.key === "Enter") {
      onEnter && onEnter();
    }
  };

  return (
    <div className="flex justify-center ">
      <input
        type="search"
        value={value}
        className={"w-full max-w-md mx-3 focus:outline-none px-6 py-4 rounded-full shadow-md " + className}
        onKeyDown={_handleKeyDown}
        {...props}
      />

      {help && <p className="text-grey-dark text-sm  mt-1">{help}</p>}
      {error && <p className="text-red text-sm italic mt-1">{error}</p>}
    </div>
  );
};

export default SearchBox;
