import React from "react";
import { Link } from "react-router-dom";

import { ui } from "../../../data";
import api from "../../../helpers/api";
import Logo from "../../../components/brand/Logo";

const Header = props => {
  const { location, hideNavigation } = props;

  const menuLinkStyle = `inline-block ${ui.props.text.light} font-light hover:${ui.props.text.highlight}  ml-5 no-underline capitalize transition-2`;

  const menuLinkMarkup = (link, index) => (
    <Link key={"nav-" + index + "-" + link.title} className={`${menuLinkStyle} ${location.startsWith(link.path) ? "font-bold" : ""}`} to={link.path}>
      {link.title}
    </Link>
  );

  return (
    <header className={`${ui.props.header.background}`}>
      <div className="container mx-auto flex items-center py-4 px-6">
        <Logo lightMode />
        {!hideNavigation && <nav className="text-base">{ui.navigation.left.map(menuLinkMarkup)}</nav>}
        <div className="flex-grow" />
        {!hideNavigation && <nav className="text-base">{ui.navigation.right.map(menuLinkMarkup)}</nav>}
        {!hideNavigation && api.user.current() && (
          <a href="/me" className="block lg:inline-block lg:mt-0 text-white hover:text-white no-underline border bg-grey-light w-8 h-8 border-1 rounded-full">
            <img className=" rounded-full" alt="" src={api.user.current().photo} />
          </a>
        )}
      </div>
    </header>
  );
};

export default Header;
