import React, { useEffect } from "react";

import { useApi } from "../../helpers/hooks";
import api from "../../helpers/api";

const Sample = props => {
  const { data, fetch } = useApi(api.admin.sample);

  useEffect(() => {
    fetch();

    // eslint-disable-next-line
  }, []);

  return <>{data}</>;
};

export default Sample;
