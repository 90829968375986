import React from "react";
import { NavLink } from "react-router-dom";

import Layout from "../Layout";

import Section from "../../components/Section";

import { OHADACover } from "../../assets";
import { ui } from "../../data";
import SearchBox from "../../components/SearchBox";
import Logo from "../../components/brand/Logo";
// import BookCard from "../../components/BookCard";

const CtaButton = ({ children, path, className }) => (
  <NavLink
    to={path}
    className={`bg-white text-sm transition-2 text-brush-darker hover:bg-transparent hover:text-white border border-white px-8 py-3 no-underline cursor-no-select cursor-no-drag uppercase rounded-full ${className}`}
  >
    {children}
  </NavLink>
);

// const BookCard = ({ title, color, showBottomBar = true, showBottomTitle = false }) => {
//   return (
//     <div className="doc-wrapper cursor-nodrag cursor-noselect">
//       <div className="doc-cover cursor-no-select cursor-nod-rag cursor-noselect" style={{ backgroundImage: `url("${OHADACover}")` }}>
//         <div className="doc-title-wrapper " style={{ color: color }}>
//           {title}
//         </div>
//         <div className="doc-joint"></div>
//       </div>

//       {showBottomTitle && <div className="doc-caption">{title}</div>}
//       {showBottomBar && <div className="border-top mt2 inline-block  width-25p mha" style={{ borderTopColor: color }} />}
//     </div>
//   );
// };

const Landing = props => {
  return (
    <Layout {...props} hideHeader>
      <Section className="pt-16 pb-32 h-full">
        <div className="flex flex-col items-stretch justify-center pb-12 mt-24 mb-32">
          <Logo className="mx-auto mb-8" height="h-24" />
          <h5 className="text-brush text-xl font-light text-center mb-6">Le droit au service de l'humanité</h5>
          <SearchBox className="cursor-not-allowed mb-24" disabled placeholder="Search Ọya Lex, coming soon..." />
          {/* <Button
            className="mt-6 mx-auto border border-transparent hover:border-brush-light outline-none cursor-not-allowed"
            fontWeight="light"
            color="grey-dark"
            background={ui.props.color.light}
          >
            {"Search Ọya Lex" || strings.search}
          </Button> */}
        </div>
      </Section>

      <Section background={ui.props.color.highlightBackground}>
        <div className="flex justify-center items-center py-12">
          <div className=" mr-10 -ml-6">
            <div className="max-w-md text-center text-white mx-auto">
              <h1 className="text-uppercase text-xl md:text-3xl lg:text-5xl">RECUEIL DE TEXTES OHADA</h1>
              <p className="text-white pt-10 pb-12">
                Tous les textes de l'OHADA, de 1993 à 2019 consolidés en un seul volume.
                <br></br>
                Nous pensons que ce Recueil contribuera fortement à la vulgarisation pleine et entière de l‘Organisation.
              </p>
              <CtaButton path="/recueilohada" className="mx-2">
                Consulter
              </CtaButton>
            </div>
          </div>
          <div className="w-1/2 lg:w-1/4">
            <img alt="" className=" rounded-r-lg rounded-l border border-brush-light cursor-no-drag" src={OHADACover} />
          </div>
        </div>
      </Section>
      <Section>
        <div className="flex justify-center items-center py-12">
          <div className="py-32"></div>
        </div>
      </Section>
    </Layout>
  );
};

export default Landing;
