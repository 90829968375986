import strings from "../strings";
import urls from "../urls";

const menu = {
  actions: [
    {
      title: strings.logOut,
      path: urls.front.logout,
      icon: "logout"
    }
  ],
  actionsBottom: [
    {
      title: strings.helpCenter,
      external: true,
      path: "http://help.canvas.com/"
    }
  ]
};

export default menu;
