import React, { useEffect } from "react";

import { useApi } from "../../helpers/hooks";
import api from "../../helpers/api";
import Layout from "../Layout";
import Hero from "../../components/Hero";
import { constants } from "../../data";
import Section from "../../components/Section";
import CardDefault from "../../components/cards/CardDefault";

const Home = props => {
  const { data, fetch } = useApi(api.user.list, constants.USER_LIST);

  useEffect(() => {
    fetch();

    // eslint-disable-next-line
  }, []);

  return (
    <Layout {...props}>
      <Hero size="small" />
      <Section>
        <div className="flex flex-wrap text-center">
          {data && data.map((user, index) => <CardDefault key={index} title={user.firstName || user.role} subtitle={user.email} photo={user.photo} />)}
        </div>
      </Section>
    </Layout>
  );
};

export default Home;
