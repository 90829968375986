import React from "react";
import { ui } from "../data";

const ActionLink = ({ title, className, onClick, ...rest }) => {
  return (
    <span
      className={`text-grey-dark inline text-xs transition-2  ${onClick ? "cursor-pointer" : ""} ${ui.props.text.highlight} hover:${
        ui.props.text.accent
      } no-underline  ${className}`}
      onClick={onClick}
      {...rest}
    >
      {title}
    </span>
  );
};

export default ActionLink;
