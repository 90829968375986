import { constants } from "../../data";
import storage from "../storage";
import { API_BASE_URL } from "../../data/params";
import axios from "axios";
import auth from "./auth";

export const sync = function(id) {
  axios.defaults.headers.common["auth"] = storage.get(constants.TOKEN); // for all requests
  return axios.get(API_BASE_URL + "/admin/sync").catch(err => {
    if (err.response && err.response.status === 401) {
      auth.logout();
    }
    return Promise.reject(err);
  });
};

export const sample = function(id) {
  axios.defaults.headers.common["auth"] = storage.get(constants.TOKEN); // for all requests
  return axios.get(API_BASE_URL + "/admin/sample").catch(err => {
    if (err.response && err.response.status === 401) {
      auth.logout();
    }
    return Promise.reject(err);
  });
};

export default { sync, sample };
