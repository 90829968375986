import React from "react";
import { NavLink } from "react-router-dom";

import Layout from "../Layout";

import Hero from "../../components/Hero";
import Section from "../../components/Section";

import { OHADACover, OyaLogo, BackgroundArt2 } from "../../assets";
import { ui, urls } from "../../data";

const CtaButtonLink = ({ children, path, className }) => (
  <NavLink
    to={path}
    className={`bg-white text-sm transition-2 text-brush-darker hover:bg-transparent hover:text-white border border-white px-8 py-3 no-underline cursor-no-select cursor-no-drag uppercase rounded-full ${className}`}
  >
    {children}
  </NavLink>
);

const CtaButton = ({ children, path, className }) => (
  <a
    href={path}
    className={`bg-white text-sm transition-2 text-brush-darker hover:bg-transparent hover:text-white border border-white px-8 py-3 no-underline cursor-no-select cursor-no-drag uppercase rounded-full ${className}`}
  >
    {children}
  </a>
);

const PayButton = ({ children, path, className }) => (
  <a
    href={path}
    className={`text-white bg-black hover:${ui.props.color.accentBackground} text-sm transition-2 px-8 py-3 no-underline cursor-no-select cursor-no-drag  rounded-full ${className}`}
  >
    {children}
  </a>
);

const OyaSection = () => (
  <Section>
    <div className="flex justify-center items-center my-24 flex-col lg:flex-row">
      <div className="max-w-md -mb-32 lg:-mr-32 lg:mb-0">
        <img className="max-w-full lg:max-w-sm rounded" alt="" src={BackgroundArt2} />
      </div>

      <div className="max-w-md -mt-32 lg:mt-0">
        <div className="shadow-box py-10 px-12 bg-white">
          <h2 className="text-black font-bold text-4xl pb-4">
            <img className="w-32" alt="" src={OyaLogo} />
          </h2>
          <div className="font-light">
            <p>
              Pour la toute première fois, et après plus de deux années de travail, une saisie manuelle, mot par mot, de tous les textes, (car indisponibles pour la plupart au
              format Word), a été consolidé en un seul volume les archives de l'OHADA, de 1993 à 2019.
            </p>
            <br />
            <p>
              En dehors des Actes uniformes et certains Règlements de fonctionnement qui sont facilement accessibles, existent plusieurs autres textes produits par l'Institution (à
              savoir ceux décrivant son fonctionnement interne, son mode financement, ceux ayant trait aux ressources humaines...) que nous avons souhaité compiler et vouspartager.
            </p>
            <br />
            <p>
              Avec plus de 900 pages, et plus de 150 textes répertoriés, vous avez désormais la possibilité via ce Recueil de textes OHADA, dans un format permettant la recherche
              aisée par mots clés, de connaître toute l’histoire de l’Organisation, et de vous en approprier aisément tous ses textes.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Section>
);

const Book = props => {
  return (
    <Layout {...props} hideNavigation>
      <Hero background="gradient-canvas" size="large">
        <div className="flex justify-center items-center">
          <div className=" mr-10 -ml-6">
            <div className="max-w-md text-center text-white mx-auto">
              <h5 className="text-white text-2xl font-light text-uppercase">Le droit au service de l'humanité</h5>
              <h1 className="text-uppercase text-5xl">RECUEIL DE TEXTES OHADA</h1>
              <p className="text-white pt-10 pb-12">
                Tous les textes de l'OHADA, de 1993 à 2019 consolidés en un seul volume.
                <br></br>
                Nous pensons que ce Recueil contribuera fortement à la vulgarisation pleine et entière de l‘Organisation.
              </p>
              <CtaButton path="#order" className="mx-2">
                Commander
              </CtaButton>
              <CtaButtonLink path={urls.store.treaties} className="mx-2">
                Lire
              </CtaButtonLink>
            </div>
          </div>
          <div className="w-1/4">
            <img className="rounded-r-lg rounded-l border border-brush-light cursor-no-drag" alt="" src={OHADACover} />
          </div>
        </div>
      </Hero>
      <OyaSection />
      <Section background={ui.props.color.lightBackground} className="border-t border-b">
        <div className="flex justify-center items-center my-24 flex-col lg:flex-row"></div>
      </Section>
      <Section id="order">
        <div className="flex justify-center items-center mt-12 mb-24 flex-col">
          <div className="flex flex-col items-center mb-12">
            <h2 className="mb-8 text-3xl">Tarifs</h2>
            <p className="font-light">Procurez-vous le Recueil de textes OHADA.</p>
          </div>

          <div className="flex justify-center w-4/5 flex-col lg:flex-row">
            <div className="w-full lg:w-1/3 border border-grey  flex flex-col rounded-sm  mb-6 lg:mx-4">
              <div className="w-full text-center py-6 bg-grey-lighter rounded-t-sm">
                <h4>E-Book</h4>
              </div>

              <p className="w-full font-light text-center py-6 ">Format numérique</p>

              <div className="w-full text-center pt-6 pb-10 order-b bg-grey-lighter hover:bg-brush transition-2 rounded-b-sm">
                <div className="text-2xl text-gray-dark font-medium ">35.000 F CFA</div>
                <div className="cursor-no-select">--</div>
                <div className="text-2xl text-gray-dark font-medium mb-4">53 €</div>
                <PayButton path="#order-ebook" className="mx-2">
                  Commander
                </PayButton>
              </div>
            </div>

            <div className="w-full lg:w-1/3 border border-grey  flex flex-col rounded-sm mb-6 lg:mx-4">
              <div className="w-full text-center py-6 bg-grey-lighter rounded-t-sm">
                <h4>Print Copy</h4>
              </div>

              <p className="w-full font-light text-center py-6 ">Format papier</p>

              <div className="w-full text-center pt-6 pb-10 order-b bg-grey-lighter hover:bg-brush transition-2 rounded-b-sm">
                <div className="text-2xl text-gray-dark font-medium ">70.000 F CFA</div>
                <div className="cursor-no-select">--</div>
                <div className="text-2xl text-gray-dark font-medium mb-4">107 €</div>
                <PayButton path="#order-print" className="mx-2">
                  Commander
                </PayButton>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* <Section background={ui.props.color.accentBackground}>
        <div className="flex justify-center items-center my-24 flex-col lg:flex-row"></div>
      </Section> */}
    </Layout>
  );
};

export default Book;
