import React from "react";
import { NavLink } from "react-router-dom";

import strings from "../../data/strings";

import { IconOpenBook, IconHistory, IconInfo, IconInfoFilled, IconBook, IconEdit, IconBack } from "../../components/Icons";
import { AppLogoMark } from "../../assets";
import "./readercards.css";

import moment from "moment";
import "moment/locale/fr";

moment.locale("fr");

const ReturnCard = ({ document }) => {
  return (
    <NavLink to={document.path} className="flex no-underline flex-col my-4 ml-4 mr-16 border rounded-sm bg-white text-grey-dark cursor-pointer hover:bg-brush-lighter">
      <div className="flex items-center px-4 py-2 uppercase text-sm">
        <IconBack fill="#888" className="mr-2" /> {strings.back + " au recueil"}
        <img alt="" src={AppLogoMark} className="h-12" />
      </div>
    </NavLink>
  );
};
const TitleCard = ({ document }) => {
  return (
    <div className="flex flex-col m-4 border  rounded-sm bg-white text-grey-dark">
      <div className="flex items-center px-4 py-3 uppercase border-b text-sm">
        <IconInfoFilled fill="#888" className="mr-2" /> {strings.title}
      </div>
      <div className="flex items-center px-4 py-4 border-b text-xs">{document.title}</div>
    </div>
  );
};

const DetailsCard = ({ document }) => {
  const createdAtDate = moment(new Date(document.createdAt));
  const updatedAtDate = moment(new Date(document.updatedAt));

  return (
    <div className="flex flex-col mx-4 border rounded-sm bg-white text-grey-dark">
      <div className="flex items-center px-4 py-3 uppercase border-b text-sm">
        <IconInfo fill="#888" className="mr-2" /> {strings.details}
      </div>

      <div className="flex flex-col  px-4 py-4 border-b text-xs">
        <div className="mb-1">
          <IconHistory fill="#868686" className="mr-2" />
          <span>
            {strings.revision} #{document.revision}
          </span>
        </div>

        <div className="mb-1">
          <IconBook fill="#868686" className="mr-2" />
          <span>{strings.created} </span>
          <span title={createdAtDate.format("llll")}>{createdAtDate.fromNow()}</span>
        </div>

        <div className="mb-1">
          <IconEdit fill="#868686" className="mr-2" />
          <span>{strings.updated} </span>
          <span title={updatedAtDate.format("llll")}>{updatedAtDate.fromNow()}</span>
        </div>
      </div>
    </div>
  );
};

// const blockTypes = {
//   h1: "h1",
//   h2: "h2",
//   h3: "h3"
// };

const TOCCard = ({ data, color }) => {
  return (
    <div className="flex flex-col m-4 border rounded-sm bg-white text-grey-dark">
      <div className="flex items-center px-4 py-3 uppercase border-b text-sm">
        <IconOpenBook fill="#888" className="mr-2" /> {strings.toc}
      </div>

      <div className="flex flex-col  px-4 py-4 border-b text-xs">
        <ul className="toc-navigation list-reset">
          {data.map((line, idx) => {
            if (line.content.name)
              return (
                <li key={idx + "tc"} title={line.content.name} className={`toc-nav-item ${line.type}  border-l border-${color} h-6 flex items-center`}>
                  <a href={"#" + line.anchor}> {line.content.name}</a>
                </li>
              );
            return <React.Fragment key={idx + "tc"}></React.Fragment>;
          })}
        </ul>
      </div>
    </div>
  );
};

export { TitleCard, DetailsCard, TOCCard, ReturnCard };
