import React from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Header from "./components/Header";
import SEO from "./components/SEO";
import Footer from "./components/Footer";

class Layout extends React.Component {
  render() {
    const { title, info, className, hideNavigation, hideHeader, hideFooter } = this.props;
    const path = this.props.location.pathname;
    return (
      <div className={`layout-wrapper ${className}`}>
        <SEO page={title} path={path} info={info} />
        <ToastContainer position="bottom-right" type="default" autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick pauseOnHover />
        {!hideHeader && <Header location={path} hideNavigation={hideNavigation} />}
        {this.props.children}
        {!hideFooter && <Footer />}
      </div>
    );
  }
}

export default Layout;
