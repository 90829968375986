import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  // en: {
  //   logIn: "Log in",
  //   logOut: "Log out",

  //   home: "Home",

  //   copyright: "© 2019 Canvas"
  // },

  fr: {
    needAnAccount: "Besoin d'un compte",
    alreadyHaveAnAccount: "Vous avez un compte",

    register: "Inscription",
    pricing: "tarifs",
    documents: "documents",
    library: "librairie",

    firstName: "prénom",
    lastName: "nom",
    company: "societé",
    title: "titre",
    description: "description",
    color: "couleur",
    draft: "brouillon",
    protected: "protégé",

    cancel: "annuler",
    save: "enregistrer",
    back: "retour",
    edit: "editer",
    delete: "supprimer",

    newDocument: "nouveau document",

    documentCreated: "Document créé",
    documentUpdated: "Document mis à jour",
    documentDeleted: "Document supprimé",

    accountCreated: "Compte créé",

    pageLeaveQuestion: "Voulez-vous vraiment quitter cette page? Les modifications non enregistrées seront perdues.",
    documentDeleteQuestion: "Voulez-vous vraiment supprimer ce document?",
    documentNotFound: "Oops!  Document non trouvé..",
    documentNotAuthorized: "Désolé vous ne pouvez pas accéder à ce document",

    toc: "Sommaire",
    navigation: "Navigation",
    details: "Détails",
    revision: "Révision",
    created: "Créé",
    updated: "Mis à jour",

    landing: {
      heroText: "Lex Africa est une base de données de textes africains, mise à jour par des juristes spécialisé ainsi que des membres des institutions étatiques et africaines."
    },

    search: "Recherche",

    logIn: "Se connecter",
    signUp: "S'inscrire",
    logOut: "Se déconnecter",
    logInMessage: "Connectez-vous",
    signUpMessage: "Inscrivez-vous",
    logInHook: "Déjà sur inscrit?",
    signUpHook: "Nouveau?",

    onSomething: "sur",

    email: "Email",
    password: "Mot de passe",

    home: "Accueil",

    copyright: "© 2019 Oyalex"
  }
});

strings.setLanguage("fr");
// strings.setLanguage("en");
export default strings;
