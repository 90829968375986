import React from "react";
import ActionLink from "../ActionLink";

const SectionHeader = ({ children, title, icon, className, action, ...rest }) => {
  return (
    <div className={`flex items-center cursor-no-select ${className}`} {...rest}>
      <span className="text-xl -mb-1 mr-2">{icon}</span>
      <h1 className="text-sm text-grey-dark font-normal uppercase">
        {title}{" "}
        {action && (
          <>
            <span>・</span>
            <ActionLink {...action} />
          </>
        )}
      </h1>
      {children}
    </div>
  );
};

export default SectionHeader;
