import { constants } from "../../data";
import storage from "../storage";
import { API_BASE_URL } from "../../data/params";
import axios from "axios";
import auth from "./auth";

const user = {
  logged: function() {
    return storage.get(constants.TOKEN) !== null;
  },

  current: function() {
    return storage.get(constants.USER);
  },

  retrieve: function(id = "current") {
    axios.defaults.headers.common["auth"] = storage.get(constants.TOKEN); // for all requests
    return axios.get(API_BASE_URL + "/users/" + id).catch(err => {
      if (err.response && err.response.status === 401) {
        auth.logout();
      }
      return Promise.reject(err);
    });
  },

  list: function() {
    axios.defaults.headers.common["auth"] = storage.get(constants.TOKEN); // for all requests
    return axios.get(API_BASE_URL + "/users").catch(err => {
      if (err.response && err.response.status === 401) {
        auth.logout();
      }
      return Promise.reject(err);
    });
  }
};

export default user;
