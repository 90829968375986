import packageJson from "../../package.json";

export const VERSION = packageJson.version;
export const APP_DESCRIPTION = packageJson.description;
export const APP_KEYWORDS = packageJson.keywords.join(", ");

export const APP_ID = "oyalex-ui";
export const APP_NAME = "Ọya Lex";
export const APP_ENV = process.env.REACT_APP_APP_ENV || "dev";

export const APP_DOMAIN = getByEnv("localhost:3000", "oyalex.kisso.app", "oyalex.kissohq.com");
export const FRONT_BASE_URL = getByEnv("http://localhost:3000", "https://oyalex.kisso.app", "https://oyalex.kissohq.com");
export const API_HOST = getByEnv("http://localhost.dv:3042", "https://api.oyalex.kisso.app", "https://api.oyalex.kissohq.com");
export const API_BASE_URL = API_HOST;

export const SENTRY_DSN = getByEnv(null, null);
export const AMPLITUDE_ID = getByEnv(null, null);
export const FB_PIXEL_ID = getByEnv(null, null);
export const INSPECTLET_ID = getByEnv(null, null);
export const GOOGLE_ANALYTICS = getByEnv(null, null);

export function getByEnv(env1, env2, env3) {
  function getByEnv_beta_prod(beta, prod) {
    const REACT_APP_APP_ENV = process.env.REACT_APP_APP_ENV;

    if (REACT_APP_APP_ENV === "prod") {
      return prod;
    }
    return beta;
  }

  function getByEnv_dev_beta_prod(dev, beta, prod) {
    const REACT_APP_APP_ENV = process.env.REACT_APP_APP_ENV;

    if (REACT_APP_APP_ENV === "prod") {
      return prod;
    }
    if (REACT_APP_APP_ENV === "beta") {
      return beta;
    }
    return dev;
  }
  if (arguments.length === 2) return getByEnv_beta_prod(env1, env2);

  return getByEnv_dev_beta_prod(env1, env2, env3);
}

export default {
  getByEnv,
  VERSION,
  APP_DESCRIPTION,
  APP_KEYWORDS,
  APP_NAME,
  APP_ENV,
  APP_DOMAIN,
  API_HOST,
  API_BASE_URL,
  FRONT_BASE_URL,
  SENTRY_DSN,
  AMPLITUDE_ID,
  FB_PIXEL_ID,
  INSPECTLET_ID,
  GOOGLE_ANALYTICS
};
