import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";

import Layout from "../Layout";
import { ui, urls, strings } from "../../data";
import Hero from "../../components/Hero";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import { useApi } from "../../helpers/hooks";
import api from "../../helpers/api";
import Section from "../../components/Section";
import Card from "../../components/Card";

const Login = props => {
  const [user, setUser] = useState({ email: "", password: "" });
  const { error, fetch } = useApi(api.auth.login);

  const done = useCallback(() => {
    window.location.href = urls.app.base;
  }, []);

  return (
    <Layout {...props} hideNavigation>
      <Hero size="small"></Hero>
      <Section className="-mt-12">
        <Card background="white" className="w-full max-w-xs mx-auto -mt-16">
          <h2 className="text-grey-darkest text-md mb-8">{strings.logInMessage}</h2>
          <Input
            label={strings.email}
            type="mail"
            placeholder={strings.email}
            name="email"
            value={user.email}
            onChange={event => setUser({ ...user, email: event.target.value })}
          />
          <Input
            label={strings.password}
            type="password"
            placeholder="********"
            name="password"
            value={user.password}
            onChange={event => setUser({ ...user, password: event.target.value })}
          />
          {error && <div className="text-xs text-red font-light mt-4 mb-2">{error.data}</div>}

          <div className="flex flex-col items-start justify-between mt-6">
            <Button className="" background={ui.props.color.accent} onClick={() => fetch({ ...user, done })}>
              {strings.logIn}
            </Button>
            <div className="text-xs mt-3">
              <span className="text-grey-dark mr-1">{strings.signUpHook}</span>
              <Link to={urls.front.signup} className="text-blue-dark no-underline">
                {strings.signUp}
              </Link>
            </div>
          </div>
        </Card>
      </Section>
    </Layout>
  );
};

export default Login;
