import React from "react";

const Card = ({ children, header, shadow, background, className, animate, ...rest }) => {
  return (
    <div className={`p-6 rounded-sm bg-${background} transition-2 border ${animate ? "hover:shadow-box hover:bg-white" : ""} ${className}`} {...rest}>
      {header && <div className="text-md font-normal mb-2 text-grey-dark">{header}</div>}
      {children}
    </div>
  );
};

Card.defaultProps = {
  background: "transparent",
  animate: true
};

export default Card;
