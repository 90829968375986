import React from "react";
// import { Link } from "react-router-dom";

import { ui } from "../../../data";
// import api from "../../../helpers/api";
import Logo from "../../../components/brand/Logo";
// import Input from "../../../components/form/Input";
// import { useApi } from "../../../helpers/hooks";

// import { AppLogoWhite } from "../../../assets";

const Footer = props => {
  // const [user, setUser] = React.useState({ email: "" });
  // const { data, fetch: subscribe } = useApi(api.newsletter.subscribe, null);

  return (
    <footer className={`${ui.props.color.accentBackground} pt-24 pb-12`}>
      <div className="container mx-auto  py-4 px-6 text-white">
        <div className="flex items-start justify-between">
          <div className="">
            <h2 className="text-xl font-bold mb-6">
              <Logo className="-mt-2" whiteMode />
            </h2>
            <p className="text-grey font-light mb-4 text-sm">
              Ọyalex est une base documentaire à l’attention des professionnels du droit, chercheurs, étudiants, entreprises, acteurs économiques, ou tout simplement justiciables
              souhaitant être au fait de la législation qui leur est applicable, de leurs droits, mais également de leurs devoirs.
            </p>
            <p className="text-grey font-light text-sm">&copy; Ọya Lex.</p>
          </div>
          {/* <div className="w-1/3">
            <h2 className="text-xl font-bold mb-6">Newsletter</h2>
            <p className="text-grey font-light text-sm mb-4">Abonnez-vous à notre lettre d’information</p>
            <Input
              className="bg-white"
              type="mail"
              placeholder={strings.email}
              name="email"
              value={user.email}
              onChange={event => setUser({ ...user, email: event.target.value })}
            />
          </div>
          <div className="w-1/5">
            <h2 className="text-xl font-bold mb-6">Suivez-nous</h2>
            <p className="text-grey font-light text-sm">Stay update with our latest</p>
          </div> */}
        </div>
        <div className=" flex justify-center mt-16 items-center">
          <span className="mr-2 text-grey-dark font-light">powered by</span>
          <a
            href="https://www.oya.africa"
            className="transition-2 text-grey-dark hover:text-grey-lighter font-bold uppercase no-underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Oya
          </a>
          <span className="mx-2 text-grey-dark font-light"> & </span>

          <a
            href="https://www.kissohq.com"
            className="transition-2 text-grey-dark hover:text-grey-lighter font-bold uppercase no-underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kisso Industries
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
