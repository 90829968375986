const urls = {
  root: "/",
  front: {
    base: "/",
    login: "/login",
    signup: "/signup",
    logout: "/logout"
  },
  utils: {
    base: "/utils",
    sample: "/sample"
  },
  store: {
    base: "/store",
    recueilohada: "/recueilohada",
    treaties: "/recueilohada/les-traités"
  },
  documents: {
    base: "/documents",
    new: "/new-document",
    details: "/documents/:slug/details",
    object: slug => (slug ? `/documents/${slug}` : "/documents/:slug"),
    edit: slug => (slug ? `/documents/${slug}/edit` : "/documents/:slug/edit")
  },
  app: {
    base: "/home",
    user: {
      base: "/users",
      object: id => (id ? `/users/${id}` : "/users/:id"),
      edit: id => (id ? `/users/${id}/edit` : "/users/:id/edit")
    }
  }
};

export default urls;
