// import amplitude from "./amplitude";
// import facebookPixel from "./facebookPixel";
// import googleAnalytics from "./googleAnalytics";
// import inspectlet from "./inspectlet";
import sentry from "./sentry";
import { params, constants } from "../../data";
import storage from "../storage";

const vendors = {
  run: function(updatedUser = null) {
    const user = updatedUser || storage.get(constants.USER);

    // googleAnalytics(user);
    // inspectlet();
    // amplitude();
    sentry(user);
    // facebookPixel();
  },
  init(updatedUser = null) {
    // const user = updatedUser || storage.get(Constants.USER);

    // this.GA.init(user);
    this.run();
  },
  GA: {
    init: function(user) {
      window.dataLayer = window.dataLayer || [];
      const data = {
        page_path: params.APP_ID + "/" + window.location.pathname,
        user_id: user.id
      };
      this.gtag("js", new Date());
      this.gtag("config", params.GOOGLE_ANALYTICS, data);
    },
    gtag: (...args) => window.dataLayer.push(...args)
  }
};

export default vendors;
