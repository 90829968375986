import React from "react";
import SectionHeader from "./SectionHeader";
import { ui } from "../../data";
import Container from "./Container";

const Section = ({ children, header, className, background, ...rest }) => {
  return (
    <section className={` px-8 ${background || ui.props.page.background}`} {...rest}>
      <Container className={`mt-12 ${className}`}>
        {header && <SectionHeader {...header} />}
        <div className="flex flex-col mt-4">{children}</div>
      </Container>
    </section>
  );
};

export default Section;
