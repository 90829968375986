import React from "react";

import Label from "./Label";
import { ui } from "../../data";

const Input = ({ value, label, error, help, border = true, disabled, type = "text", className, inputAttributes, ...props }) => {
  const InputElem = type === "textarea" ? "textarea" : "input";

  let inputStyle = "";
  if (border) inputStyle += "border ";
  else inputStyle += " ";

  if (disabled) inputStyle += "bg-grey-light cursor-not-allowed ";
  else inputStyle += "bg-transparent ";

  if (error) inputStyle += "border-red ";
  else inputStyle += " focus:" + ui.props.color.accent;

  return (
    <div className={`mb-3 ${className}`}>
      {label && <Label className="mb-2">{label}</Label>}
      <InputElem
        value={value}
        disabled={disabled}
        type={type}
        className={`shadow appearance-none rounded-sm w-full  py-2 px-3 text-grey-darker leading-tight focus:outline-none ${inputStyle}`}
        {...inputAttributes}
        {...props}
      />
      {help && <p className="text-grey-dark text-sm  mt-1">{help}</p>}
      {error && <p className="text-red text-sm italic mt-1">{error}</p>}
    </div>
  );
};

export default Input;
