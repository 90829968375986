import { constants } from "../../data";
import storage from "../storage";
import { API_BASE_URL } from "../../data/params";
import axios from "axios";
import auth from "./auth";

const newsletter = {
  subscribe: function(data) {
    axios.defaults.headers.common["auth"] = storage.get(constants.TOKEN); // for all requests
    return axios.post(API_BASE_URL + "/newsletter/subscribers", data).catch(err => {
      return Promise.reject(err);
    });
  },

  list: function() {
    axios.defaults.headers.common["auth"] = storage.get(constants.TOKEN); // for all requests
    return axios.get(API_BASE_URL + "/newsletter/subscribers").catch(err => {
      if (err.response && err.response.status === 401) {
        auth.logout();
      }
      return Promise.reject(err);
    });
  }
};

export default newsletter;
