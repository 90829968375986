import React from "react";
import Helmet from "react-helmet";
import { APP_NAME, APP_DESCRIPTION, FRONT_BASE_URL, APP_KEYWORDS } from "../../../data/params";

const schemaOrgJSONLD = [
  {
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: FRONT_BASE_URL,
    name: APP_NAME,
    alternateName: APP_NAME
  }
];

const SEO = ({ page, path, info }) => {
  const name = APP_NAME;
  const title = page ? page + " · " + APP_NAME : APP_NAME;
  const description = info ? info : APP_DESCRIPTION;
  const url = path ? FRONT_BASE_URL + path : FRONT_BASE_URL;
  const keywords = APP_KEYWORDS;

  const image = null;
  const ban_square = null;

  return (
    <Helmet title={title}>
      {/* General tags */}
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <meta name="keywords" content={keywords} />

      {/* Schema.org tags */}
      <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={name} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ban_square} />
      {/*<meta property="fb:app_id" content={config.fbAppID}/>*/}

      {/* Twitter Card tags */}
      <meta name="twitter:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={name} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      {/*<meta name="twitter:creator" content={config.twitter}/>*/}

      {/*Shema.org microdata*/}
      <meta itemProp="name" content="" />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image} />
    </Helmet>
  );
};

export default SEO;
