import React from "react";
import { Link } from "react-router-dom";
import { ui } from "../../data";

const CardDefault = ({ title, subtitle, photo, path }) => {
  return (
    <div className="w-64 px-2 pb-4">
      <Link
        className={`w-full  transition-2 flex flex-col items-center py-5 no-underline rounded-sm border hover:shadow-box hover:${ui.props.color.lightBackground}`}
        to={path || "#"}
      >
        <div className="rounded-full bg-cover w-16 h-16 bg-grey-light border" style={{ backgroundImage: `url(${photo})` }} />

        <div className="name border-t border-b py-1 w-full text-grey-darkest font-semibold text-lg mt-5">{title}</div>
        <div className={`${ui.props.text.highlight} font-light mt-4 h-6`}>{subtitle}</div>
      </Link>
    </div>
  );
};

export default CardDefault;
