import React from "react";

const Button = ({ color = "white", background = "grey-darker", fontWeight, className = "", children, ...props }) => (
  <button
    className={`bg-${background} hover:bg-${background}-dark text-${color} font-${fontWeight ? fontWeight : "bold"} py-2 px-4 rounded focus:outline-none  ${className}`}
    {...props}
  >
    {children}
  </button>
);

export default Button;

// focus:shadow-outline
