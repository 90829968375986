import React from "react";
import { ui } from "../data";

const Hero = ({ className, children, background, size = "medium", ...props }) => {
  return (
    <section className={`${background || ui.props.page.hero.background}`}>
      <div id="hero" className={`container mx-auto px-6 py-2 flex ${className || ""}`} style={size && { height: ui.props.page.hero.height[size] }} {...props}>
        {children}
      </div>
    </section>
  );
};

export default Hero;
