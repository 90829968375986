const Colors = {
  accent: "canvas",
  highlight: "brush",
  light: "white"
};

const UISettings = {
  accentColor: Colors.accent,
  lightColor: Colors.light,
  highlightColor: Colors.highlight,
  accentBackground: `bg-${Colors.accent}`,
  accentBlockBackground: `bg-${Colors.accent}`,
  highlightBackground: `bg-${Colors.highlight}`,
  lightBackground: `bg-${Colors.light}`,
  accentText: `text-${Colors.accent}`,
  highlightText: `text-${Colors.highlight}`,
  lightText: `text-${Colors.light}`,
  backgroundColor: Colors.light
};

// ------------------------

const TextProps = {
  accent: UISettings.accentText,
  highlight: UISettings.highlightText,
  light: UISettings.lightText,
  h1: "font-light "
};

const ColorProps = {
  accent: UISettings.accentColor,
  highlight: UISettings.highlightColor,
  light: UISettings.lightColor,
  accentBackground: UISettings.accentBackground,
  accentBlockBackground: UISettings.accentBlockBackground,
  highlightBackground: UISettings.highlightBackground,
  lightBackground: UISettings.lightBackground
};

const HeaderProps = {
  background: UISettings.accentBackground
};

const PageProps = {
  background: UISettings.backgroundColor,
  hero: {
    background: UISettings.accentBackground,
    blockBackground: UISettings.accentBlockBackground,
    height: {
      small: "240px",
      medium: "400px",
      large: "800px"
    }
  }
};

// ------------------------

const props = {
  color: ColorProps,
  text: TextProps,
  page: PageProps,
  header: HeaderProps
};

export default props;
