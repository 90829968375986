import React from "react";
import { Link } from "react-router-dom";

import { urls } from "../../data";

import { AppLogoLight, AppLogoWhite, AppLogo } from "../../assets";

const Logo = ({ className, lightMode, whiteMode, height, ...props }) => (
  <div className={`flex items-center flex-no-shrink ${className}`}>
    <Link to={urls.root} className="tracking-tight no-underline capitalize flex items-center">
      <img alt="oyalex" className={`${height || "h-10"} mr-1`} src={whiteMode ? AppLogoWhite : lightMode ? AppLogoLight : AppLogo} />
    </Link>
  </div>
);
export default Logo;
