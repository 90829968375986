import React from "react";

const IconBase = ({ path, fill, width, height, className, ...props }) => {
  fill = fill || "#000";
  width = width || "1em";
  height = height || "1em";
  className = "svg-icon " + className;

  return (
    <svg className={className} {...props} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} d={path} />
    </svg>
  );
};

const IconAddCircle = props => (
  <IconBase
    {...props}
    path="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
  />
);

const IconLogin = props => (
  <IconBase {...props} path="M21 3.01H3c-1.1 0-2 .9-2 2V9h2V4.99h18v14.03H3V15H1v4.01c0 1.1.9 1.98 2 1.98h18c1.1 0 2-.88 2-1.98v-14c0-1.11-.9-2-2-2zM11 16l4-4-4-4v3H1v2h10v3z" />
);

const IconLogout = props => (
  <IconBase
    {...props}
    path="M14.29 8.207l1.41-1.41 5 5-5 5-1.41-1.41 2.58-2.59H7.2v-2h9.67zM5 3h14a2 2 0 0 1 2 2v4h-2V5H5v14h14v-4h2v4a2 2 0 0 1-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2z"
  />
);

const IconSettings = props => (
  <IconBase
    {...props}
    path="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"
  />
);

const IconBook = props => <IconBase {...props} path="M18 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 4h5v8l-2.5-1.5L6 12V4z" />;

const IconFolder = props => <IconBase {...props} path="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z" />;

const IconAdd = props => <IconBase {...props} path="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />;

const IconSave = props => (
  <IconBase {...props} path="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z" />
);

const IconBack = props => <IconBase {...props} path="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />;

const IconCaretDown = props => <IconBase {...props} path="M7 10l5 5 5-5z" />;

const IconMore = props => <IconBase {...props} path="M7 10l5 5 5-5z" />;

const IconHome = props => (
  <IconBase
    {...props}
    path="m22.6380012 12.8596788c-.2221103.2163592-.515898.3327157-.8227581.3344777-.3193056 0-.6278764-.1292907-.8579676-.3669961l-.0577738-.0608344v9.0456826c0 .656074-.5298768 1.1879914-1.185352 1.1879914h-4.7857152c-.6554753 0-1.185352-.5319174-1.185352-1.1879914v-6.3700858h-4.46284132l-.01998149 6.3746322c-.00337457.653801-.53255383 1.183445-1.18534535 1.183445h-4.78865241c-.65472361 0-1.18535201-.5321466-1.18535201-1.1879914v-9.0473144l-.05943696.0622021c-.45360917.4757453-1.20440336.4904925-1.67656889.0351947-.47214343-.4535761-.48834693-1.2049559-.03558675-1.6781148l10.34139668-10.81758155c.2235079-.23456728.5324736-.36639465.8564164-.36639465.3239427 0 .6329084.13182738.8567507.366746l10.2879312 10.8179828c.451158.4735191.4354114 1.2241965-.0338111 1.67495z"
  />
);

const IconLock = props => (
  <IconBase
    {...props}
    path="m12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1v-2c0-2.76-2.24-5-5-5s-5 2.24-5 5v2h-1c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm-9.1-2c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2h-6.2zm9.1 14h-12v-10h12z"
  />
);

const IconDelete = props => <IconBase {...props} path="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />;

const IconEdit = props => (
  <IconBase
    {...props}
    path="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
  />
);

const IconExt = props => (
  <IconBase {...props} path="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />
);

const IconSearch = props => (
  <IconBase
    {...props}
    path="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
  />
);

const IconCaretLeftCircle = props => <IconBase {...props} path="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm2-14.5v9L8 12z" />;

const IconCaretRightCircle = props => <IconBase {...props} path="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5v-9l6 4.5-6 4.5z" />;

const IconFile = props => <IconBase {...props} path="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z" />;

const IconHistory = props => (
  <IconBase
    {...props}
    path="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z"
  />
);

const IconInfo = props => (
  <IconBase
    {...props}
    path="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"
  />
);

const IconInfoFilled = props => <IconBase {...props} path="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />;

const IconOpenBook = props => (
  <IconBase
    {...props}
    path="m21 5c-1.11-.35-2.33-.5-3.5-.5-1.95 0-4.05.4-5.5 1.5-1.45-1.1-3.55-1.5-5.5-1.5s-4.05.4-5.5 1.5v14.65c0 .25.25.5.5.5.1 0 .15-.05.25-.05 1.35-.65 3.3-1.1 4.75-1.1 1.95 0 4.05.4 5.5 1.5 1.35-.85 3.8-1.5 5.5-1.5 1.65 0 3.35.3 4.75 1.05.1.05.15.05.25.05.25 0 .5-.25.5-.5v-14.6c-.6-.45-1.25-.75-2-1zm0 13.5c-1.1-.35-2.3-.5-3.5-.5-1.7 0-4.15.65-5.5 1.5v-11.5c1.35-.85 3.8-1.5 5.5-1.5 1.2 0 2.4.15 3.5.5z"
  />
);

export {
  IconAddCircle,
  IconHome,
  IconLogin,
  IconLock,
  IconOpenBook,
  IconFile,
  IconHistory,
  IconInfo,
  IconInfoFilled,
  IconLogout,
  IconSettings,
  IconBook,
  IconFolder,
  IconAdd,
  IconSave,
  IconBack,
  IconCaretDown,
  IconMore,
  IconDelete,
  IconEdit,
  IconExt,
  IconSearch,
  IconCaretLeftCircle,
  IconCaretRightCircle
};
