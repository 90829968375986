import constants from "../data/constants";

const storage = {
  get: function(key) {
    const data = localStorage.getItem(key);
    if (data != null && data !== "undefined") return JSON.parse(data).value;
    return null;
  },
  getData: function(key) {
    const data = localStorage.getItem(`${constants.PREFIX}-${key}`);
    if (data != null && data !== "undefined") return JSON.parse(data).value;
    return null;
  },
  set: function(key, value) {
    return localStorage.setItem(key, JSON.stringify({ value }));
  },
  setData: function(key, value) {
    return localStorage.setItem(`${constants.PREFIX}-${key}`, JSON.stringify({ value }));
  },
  remove: function(key) {
    return localStorage.removeItem(key);
  },
  clear: function clear() {
    localStorage.clear();
  }
};

export default storage;
