import React from "react";

import { TitleCard, DetailsCard, TOCCard, ReturnCard } from "./ReaderCards";

import ohada from "../../data/books/ohada";

import "./readerpage.css";
import Layout from "../Layout";

function flattenDocument(document) {
  let headings = {
    "h-1": 0,
    "h-2": 0,
    "h-3": 0
  };
  let res = [];
  const { headerOneList } = document.value;

  for (let idx = 0; idx < headerOneList.length; idx++) {
    const { name, info, headerTwoList } = headerOneList[idx];

    res.push({ type: "h1", content: { name, info }, anchor: `heading-one-${headings["h-1"]++}` });

    for (let i = 0; i < headerTwoList.length; i++) {
      const { headerThreeList, ...headerTwo } = headerTwoList[i];

      res.push({ type: "h2", content: { name: headerTwo.name }, anchor: `heading-two-${headings["h-2"]++}` });

      for (let pos = 0; pos < headerThreeList.length; pos++) {
        const article = headerThreeList[pos];

        res.push({ type: "h3", content: { name: article.name }, anchor: `heading-three-${headings["h-3"]++}` });

        for (let y = 0; y < article.paragraphs.length; y++) {
          const paragraph = article.paragraphs[y];

          res.push({ type: "p", content: { value: paragraph.value, style: paragraph.style } });
        }
      }
    }
  }

  //   console.log(res);
  return res;
}

const Line = ({ type, content, anchor }) => {
  if (type === "h1")
    return (
      <>
        <h1 id={anchor} className={`text-xl text-center font-bold mt-12 py-4`}>
          {content.name}
        </h1>
        {content.info && <p className="text-base italic text-center mb-2 text-grey-darkest font-light">{content.info}</p>}
        <div className="border-b mb-4" />
      </>
    );
  if (type === "h2")
    return (
      <h2 id={anchor} className={`text-base text-center uppercase font-bold my-4`}>
        {content.name}
      </h2>
    );
  if (type === "h3")
    return (
      <h3 id={anchor} className={`text-sm text-left font-bold italic mt-2 mb-1`}>
        {content.name}
      </h3>
    );
  if (type === "p") return <p className={`text-sm text-left mb-2 ${content.style}`}>{content.value}</p>;
  return <></>;
};

const Reader = props => {
  const document = ohada;

  const lines = flattenDocument(document);
  return (
    <Layout {...props} className="h-full" hideFooter hideHeader>
      <div className="h-full w-full flex cursor-no-select " id="reader-page">
        <div className="flex flex-col max-w-xs bg-reader-menu w-full overflow-y-auto overflow-x-hidden">
          <ReturnCard document={document} />
          <TitleCard document={document} />
          <DetailsCard document={document} />
          <TOCCard data={lines} color={document.color} />
        </div>
        <div className="flex flex-col w-full shadow-border px-16 py-12 overflow-y-auto overflow-x-hidden">
          {lines.map((line, idx) => (
            <React.Fragment key={"section-" + idx}>
              <Line {...line} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Reader;

// <div id="reader-page" className="reader-page bg-color-light">
// <div className=" side-bar-container">
//   <TitleCard document={document} />
//   <DetailsCard document={document} />
//   {/* <TOCCard navigationTree={navigationTree} color={document.color} /> */}
// </div>
// <div className="document-content-container cursor-noselect cursor-nodrag">
//   <div className="document-content-wrapper cursor-noselect cursor-nodrag">
//     <div className="document-content cursor-noselect cursor-nodrag"></div>
//   </div>
// </div>
// </div>
